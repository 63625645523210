import { colors } from 'constants/index';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import styled from 'styled-components';

const IncludesWrapper = styled.div<{ isDragging: boolean }>`
height: 100%;
  background: ${({ isDragging }) => (isDragging ? colors.white50 : '')};
  padding: ${({ isDragging }) => (isDragging ? '5px' : '0px')};
  border-radius: 5px;
  z-index: 2;
//   opacity: ${({ isDragging }) => (isDragging ? 1 : 0)};
`;

const ExcludesWrapper = styled.div<{ isDragging: boolean }>`
// height: 100%;

  background: ${({ isDragging }) => (isDragging ? colors.white50 : '')};
  padding: ${({ isDragging }) => (isDragging ? '5px' : '0px')};
  border-radius: 5px;
  z-index: 2;
//   opacity: ${({ isDragging }) => (isDragging ? 1 : 0)};
`;

const Wrapper = styled.div<{ type: string }>`
margin: 0 !important;
    ${({ type }) => (type !== 'excludes' ? `height: 100%;` : '')}
`;

export const TargetBox = ({ source, content }: any) => {
    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: source,
            drop: async (item: { type: string; id: number | undefined }, monitor) => { },
            collect: (monitor: DropTargetMonitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        []
    );

    // logic for Drag and Drop Outcomes receivers
    return (
        <Wrapper type={source}>
            {source === 'includes' && (<IncludesWrapper ref={drop} isDragging={isOver && canDrop}>
                <div style={{ color: colors.white05, padding: '10px 20px', height: '100%' }}>{isOver && canDrop ? 'Release to drop' : '.'}{content}</div>
            </IncludesWrapper>)}
            {source === 'excludes' && (<ExcludesWrapper ref={drop} isDragging={isOver && canDrop}>
                <div style={{ color: colors.white05, padding: '10px 20px', height: '100%' }}>{isOver && canDrop ? 'Release to drop' : '.'}
                {content}</div>
            </ExcludesWrapper>)}
        </Wrapper>

    );
};