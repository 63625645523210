import { zIndex } from 'constants/index';
import {
  Stakeholder,
  usePostItGroupsWithCardsQuery,
  Step,
} from 'data/graphql/generated';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { SubMenu } from 'components/shared';
import { OutcomesMenu } from './shared/OutcomesMenu';
import { usePostItCards } from 'hooks/usePostItCards';
import { ErrorModal } from './ErrorModal';
import { PostItGroupAndCards } from 'types';
import { polling } from 'constants/index';
import { useEffect } from 'react';

interface Props {
  stakeholder?: Stakeholder;
  offsetTopOverride?: number;
  refetchGroupsWithCards(): void;
}

const OutcomesButtonWrapper = styled.div<{ open: boolean }>`
  z-index: ${zIndex.observationsBtn};

  opacity: ${({ open }) => Number(!open)};
  pointer-events: ${({ open }) => (open ? 'none' : 'all')};
  transition: all 0.1s;
  @media ${device.tabletMax} {
    top: 175px;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: string;
  competitorId: string;
}

export const Outcomes = ({
  offsetTopOverride,
  refetchGroupsWithCards,
}: Props) => {
  const {
    drugId,
    strategyId,
    stakeholder,
  }: URLParams = useParams();

  const [open, setOpen] = useState(false);
  const [deleteErrorModal, setDeleteErrorModal] = useState(false);

  const {
    data: group,
    refetch,
    loading,
    startPolling,
    stopPolling,
  } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
      },
    },
    fetchPolicy: 'no-cache',
  });
  
  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [
    startPolling,
    stopPolling,
  ]);

  const {
    createCard,
    updateCard,
    removeCard,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        // stakeholderDefinitionId: Number(stakeholderDefinitionId),
        // competitorId: Number(competitorId),
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keystakeholders,
      // stakeholderDefinitionId: Number(stakeholderDefinitionId),
    }
  );

  // filter groups for "What are the outcomes that matter to" and for Patients, HPC and Payors only
  const mappedGroups = useMemo(() => group?.postItGroups.items.filter(p => p.title.includes('What are the outcomes that matter to') && p.stakeholder !== Stakeholder.Provider && p.stakeholder !== Stakeholder.Policymaker).reduce((acc, val) => {
    return [
      ...acc,
      {
        ...val,
        cards:
          val.cards?.filter((c) => c?.postItGroupId === val.id)
            .sort((cardA, cardB) => {
              return (cardB?.pos || 0) - (cardA?.pos || 0);
            }).map(e => {
              return { ...e, stakeholder: val.stakeholder }
            }) || [],
      },
    ];
  }, [] as PostItGroupAndCards[]), [group])

  const selectedPostItGroup = useMemo(() => mappedGroups?.filter(e => e.stakeholder === stakeholder)?.[0], [mappedGroups, stakeholder])

  return (
    <>
      <OutcomesButtonWrapper open={false}>
        <SubMenu
          icon="Target"
          text="Outcomes"
          onClick={() => {
            setOpen(true);
          }}
        />
      </OutcomesButtonWrapper>
      <OutcomesMenu
        readonly
        loading={loading}
        refetch={refetch}
        addCard={createCard}
        updateCard={updateCard}
        removeCard={async (vals) => {
          try {
            return await removeCard(vals);
          } catch (err) {
            if (err instanceof Error && (err.message === 'DELETE_ERROR' || err.message === 'Card Something went wrong')) {
              setDeleteErrorModal(true);
            }
          }
        }}
        groups={mappedGroups}
        selectedOutcomes={selectedPostItGroup}
        refetchGroupsWithCards={refetchGroupsWithCards}
        setOpen={setOpen}
        open={open}
        offsetTopOverride={offsetTopOverride}
      />

      <ErrorModal
        visible={deleteErrorModal}
        handleClose={() => setDeleteErrorModal(false)}
        title="Cannot delete this outcome"
        text="Content in one or more stakeholders depends on this outcome. You may move it to “Not included”."
      />
    </>
  );
};
