import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import { PageNav } from 'components/PageNav';
import apolloClient from 'data/apollo';
import buildStrategyURL from 'utils/buildStrategyURL';
import { ButtonLabel, ButtonRound, Icon } from './shared';
import { zIndex } from 'constants/index';
import { ExcludedFeature, IncludedFeature } from 'types';
import getInitials from 'utils/getInitials';
import { useDrugsQuery, useSignOutMutation } from 'data/graphql/generated';
import { NotificationBell } from './notifications/NotificationBell';
import { checkIfFullNmblr } from 'containers/Strategies';

const Desktop = styled.div`
  display: none;

  @media screen and (min-width: 945px) {
    display: block;
  }
`;

const DesktopWrapper = styled.nav`
  background: ${colors.black};
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  text-align: left;
  z-index: ${zIndex.nav};
`;

const NavItems = styled.div`
  overflow: hidden;
  display: inline-block;
`;

const Logo = styled.div`
  height: 50px;
  width: 60px;
  display: inline-block;
  overflow: hidden;

  a {
    width: 60px;
    height: 100%;
    display: grid;
    vertical-align: top;
    place-content: center;
  }
`;

const NavItem = styled.div<{ active: boolean; show: boolean }>`
  height: 50px;
  width: 60px;
  display: ${({ show }) => (show ? 'inline-block' : 'none')};
  overflow: hidden;

  a {
    width: 60px;
    height: 100%;
    text-align: center;
    display: grid;
    vertical-align: top;
    place-content: center;
    transition: background 0.3s;

    ${({ active }) =>
    active
      ? css`
            background: ${colors.purple};
          `
      : css`
            &:hover {
              background: #3c0c7d;
            }
            &:active {
              background: #460a93;
            }
          `}
  }
`;

const CurrentPageWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Profile = styled.div`
  display: inline-block;
  float: right;
  padding: 5px 20px 5px 15px;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

const DropDown = styled.div`
  background: ${colors.white};
  position: absolute;
  top: 45px;
  right: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px solid ${colors.black30a};
  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: 5px;

  > ${ButtonLabel} {
    padding: 5px 15px;
    border-radius: 5px;
    a {
      color: inherit;
    }
    &:hover {
      background: ${colors.greyLight};
    }
  }
`;

const DropDownBG = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  /* z-index: 208; */
  height: 100vh;
  width: 100vw;
`;

interface Props {
  title?: string;
  url?: string;
  prev: { url: string; title: string };
  next: { url: string; title: string };
  drugId: string;
  strategyId: string;
  hideArrows: boolean;
  exclude?: ExcludedFeature[];
  include?: IncludedFeature[];
  showNotifications(): void;
  notificationsVisible: boolean;
  notificationsUnread: number;
  showAccountModal(): void;
  stepNumber?: string;
  desktopNavRef?: React.RefObject<HTMLElement>;
}

export const NavbarDesktop: React.FC<Props> = ({
  title,
  url,
  prev,
  next,
  drugId,
  strategyId,
  hideArrows,
  exclude,
  include,
  showNotifications,
  notificationsVisible,
  notificationsUnread,
  showAccountModal,
  stepNumber,
  desktopNavRef,
}) => {
  const [auth, setAuth] = useAuthContext();
  const [signOut] = useSignOutMutation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const isLandingPage = !!pathname.match(/^\/$/g);
  const strategyHome = buildStrategyURL(drugId, strategyId, '');
  const welcomeHome = buildStrategyURL(drugId, strategyId, 'welcome');
  const peoplePage = buildStrategyURL(drugId, strategyId, 'people');
  // const filesPage = buildStrategyURL(drugId, strategyId, 'files');
  const initials = getInitials(auth?.user?.name);
  // The regex below matches- "strategy/7" but does not match "strategy/7/1_1"
  const matchStrategy = !!pathname.match(/welcome\/\d+\/?$/g);
  const matchWelcome = !!pathname.match(/(?:d\/\d+\/)?strategy\/\d+\/?(?:welcome)?$/g);

  const { data: drugData } = useDrugsQuery({
    variables: {
      where: {
        id: Number(drugId),
      },
    },
    fetchPolicy: 'network-only',
  });

  const possibleDrugs = drugData?.drugs?.items;
  const miniFeatureSet = useMemo(() => {
    if (possibleDrugs) {
      return possibleDrugs.filter(i => i.id === Number(drugId)).flatMap((p: any) =>
        p.ProductOnDrugs?.flatMap((f: any) =>
          f.Product?.features?.map((g: any) => g.featureSet)
        )
      );
    }
    return [];
  }, [drugId, possibleDrugs]);

  const isFullNmblr = checkIfFullNmblr(miniFeatureSet);
  return (
    <Desktop>
      <DesktopWrapper className={'main__desktopNav'} ref={desktopNavRef}>
        <NavItems>
          <Logo>
            <Link to="/">
              <Icon name="Home" color={colors.cream} size={30} />
            </Link>
          </Logo>

          {exclude?.includes('drugLink') ? null : (
            <NavItem show={!isLandingPage} active={matchStrategy || matchWelcome}>
              <Link to={drugId && strategyId && isFullNmblr ? strategyHome : drugId && strategyId && !isFullNmblr ? welcomeHome : '/'}>
                <Icon
                  name="Strategy"
                  color={colors.cream}
                  size={30}
                  className="cypress-strategy-bubbles-button"
                />
              </Link>
            </NavItem>
          )}
          {/* {exclude?.includes('filesLink') ? null : (
            <NavItem show={!isLandingPage} active={pathname.includes('/files')}>
              <Link to={drugId && strategyId ? filesPage : '/'}>
                <Icon name="Files" color={colors.cream} size={30} />
              </Link>
            </NavItem>
          )} */}
          {exclude?.includes('strategyLink') ? null : (
            <NavItem
              show={!isLandingPage}
              active={pathname.includes('/people')}
            >
              <Link to={drugId && strategyId ? peoplePage : '/'}>
                <Icon name="People" color={colors.cream} size={30} />
              </Link>
            </NavItem>
          )}

          {include?.includes('productLink') && (
            <NavItem
              show={!isLandingPage}
              active={pathname.includes('/products')}
            >
              <Link to={'/products'}>
                <Icon name="Product" color={colors.cream} size={30} />
              </Link>
            </NavItem>
          )}
        </NavItems>

        {!!title && !isLandingPage ? (
          <CurrentPageWrapper>
            <PageNav
              hideArrows={hideArrows}
              prev={prev}
              next={next}
              title={title}
              url={url}
              stepNumber={stepNumber}
            />
          </CurrentPageWrapper>
        ) : null}

        {exclude?.includes('profile') ? null : (
          <Profile onClick={() => setDropdownVisible(true)}>
            <ButtonRound
              className="cypress-initials"
              text={initials}
              level="primary"
              size={40}
            />
          </Profile>
        )}

        {exclude?.includes('notifications') ? null : (
          <NotificationBell
            onClick={showNotifications}
            visible={notificationsVisible}
            notificationsUnread={notificationsUnread}
          />
        )}
        {dropdownVisible && (
          <DropDownBG onClick={() => setDropdownVisible(false)} />
        )}
        {dropdownVisible && (
          <DropDown>
            <ButtonLabel
              onClick={async () => {
                showAccountModal();
                setDropdownVisible(false);
              }}
            >
              Account
            </ButtonLabel>
            <ButtonLabel
              onClick={() => {
                setDropdownVisible(false);
              }}
            >
              <a href="/terms/terms-of-service" target="_blank">
                Terms and Policies
              </a>
            </ButtonLabel>
            <ButtonLabel
              onClick={async () => {
                await signOut();
                history.push('/signin');
                setDropdownVisible(false);
                setAuth({
                  loading: false,
                  user: undefined,
                });
                apolloClient.clearStore();
                Sentry.configureScope((scope) => scope.setUser(null));
              }}
            >
              Sign out
            </ButtonLabel>
          </DropDown>
        )}
      </DesktopWrapper>
    </Desktop>
  );
};