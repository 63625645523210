import React, { useRef, useEffect, useState } from 'react';

import {
  ButtonRound,
  TabGroup,
  KeyStakeholderCreator,
  TooltipWrapper,
} from 'components/shared';
import {
  StakeholderDefinitionsDocument,
  useStakeholderDefinitionCreateMutation,
  useStakeholderDefinitionUpdateMutation,
  useStakeholderDefinitionDeleteMutation,
  StakeholderDefinitionsQueryVariables,
  StakeholderDefinitionsQuery,
  Stakeholder,
  StakeholderDefinition,
} from 'data/graphql/generated';
import {
  apolloCreateHelper,
  apolloUpdateHelper,
  apolloDeleteHelper,
} from 'utils/apolloQueryHelpers';
import { verifyUserRole } from 'utils/verifyUserRole';
import { useAuthContext } from 'contexts/AuthContext';
import { StakeholderFullString } from 'types';
import styled from 'styled-components/macro';
import { ErrorModal } from './ErrorModal';

interface Props {
  drugId: string;
  strategyId: string;
  data: StakeholderDefinitionsQuery;
  stakeholderTab: StakeholderFullString;
  stakeholder: Stakeholder;
}

export const StyledButtonRound = styled(ButtonRound)<{ disabled: boolean }>`
  margin-left: 9px;
  opacity: ${({ disabled }) => disabled && '0.5'};
`;

export const StakeholderDefinitions: React.FC<Props> = ({
  drugId,
  strategyId,
  data,
  stakeholder,
  stakeholderTab,
}) => {
  const [{ user }] = useAuthContext();

  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [disableCreateButton, setDisableCreateButton] = useState(false);

  const [focused, setFocused] = useState<number | undefined>(); // id
  const queryVars: StakeholderDefinitionsQueryVariables = {
    where: { strategyId: Number(strategyId), stakeholder },
  };

  const [
    createStakeholderDefinition,
  ] = useStakeholderDefinitionCreateMutation();
  const [
    updateStakeholderDefinition,
  ] = useStakeholderDefinitionUpdateMutation();
  const [
    deleteStakeholderDefinition,
  ] = useStakeholderDefinitionDeleteMutation();

  //Ref can be used to force a scroll to a particular position
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const previousWidth = useRef(0);

  const effectHasRun = useRef(false);
  useEffect(() => {
    // Scroll to the end when we add a new patient
    const effectHasRunValue = effectHasRun.current;
    const timer = setTimeout(() => {
      if (effectHasRunValue) {
        if (scrollContainerRef.current) {
          previousWidth.current = scrollContainerRef.current?.offsetWidth;
          if (
            scrollContainerRef.current.scrollWidth -
              scrollContainerRef.current.offsetWidth >
            40
          )
            scrollContainerRef.current.scrollLeft =
              scrollContainerRef.current?.offsetWidth * 2;
        }
      }
    }, 500);

    effectHasRun.current = true;
    return () => clearTimeout(timer);
  }, [data?.stakeholderDefinitions?.items]);

  // TODO we need a generic loading state
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const stakeholderDefinitions = data?.stakeholderDefinitions?.items;
  const sortedDefinitions = (stakeholderDefinitions || [])
    .slice()
    .sort((a, b) => {
      if (a?.createdAt && b?.createdAt) {
        const aTime = Number(a.createdAt);
        const bTime = Number(b.createdAt);
        return aTime < bTime ? -1 : aTime > bTime ? 1 : 0;
      }
      return 0;
    });

  const lastDefId = sortedDefinitions.length
    ? sortedDefinitions?.[sortedDefinitions?.length - 1].id
    : -1;

  return (
    <>
      <ErrorModal
        title="Cannot delete this stakeholder definition"
        text="Content in later steps depends on this stakeholder definition. Remove content and try again."
        visible={showCannotDeleteModal}
        handleClose={() => setShowCannotDeleteModal(false)}
      />

      <TabGroup
        scrollContainerRef={scrollContainerRef}
        componentName="KeyStakeholderCreator"
      >
        <div
          style={{
            display: 'flex',
            cursor: !isLead ? 'not-allowed' : 'default',
            padding: '15px 9px 15px 0',
          }}
        >
          {sortedDefinitions.map((stakeholderDefinition) => {
            const { image, title, id } = stakeholderDefinition;
            return (
              <div
                key={id}
                style={{
                  display: 'inline-block',
                }}
              >
                <TooltipWrapper
                  text={isLead ? '' : 'Only Leads can edit'}
                  effect="float"
                  place="bottom"
                >
                  <div style={{ pointerEvents: !isLead ? 'none' : 'all' }}>
                    <KeyStakeholderCreator
                      stakeholderDefinition={
                        stakeholderDefinition as StakeholderDefinition
                      }
                      placeholder={
                        isLead
                          ? `Type a ${stakeholderTab.toLocaleLowerCase()} definition`
                          : 'A Lead will enter a target definition here'
                      }
                      editable={isLead}
                      onDelete={async () => {
                        try {
                          await deleteStakeholderDefinition({
                            variables: {
                              id,
                            },
                            update: apolloDeleteHelper({
                              responseField: 'stakeholderDefinitionDelete',
                              query: StakeholderDefinitionsDocument,
                              queryVars,
                              queryName: 'stakeholderDefinitions',
                            }),
                            optimisticResponse: {
                              stakeholderDefinitionDelete: {
                                ...stakeholderDefinition,
                              },
                            },
                          });

                          // if deleting the last stakeholder definition, ensure the Add button is re-enabled
                          setDisableCreateButton(false);
                        } catch (error) {
                          console.error(error);

                          if (
                            error 
                          ) {
                            return setShowCannotDeleteModal(true);
                          }
                          throw error;
                        }
                      }}
                      imageURL={image}
                      onImageUpload={(imageUrl) => {
                        try {
                          updateStakeholderDefinition({
                            variables: {
                              id,
                              data: { image: imageUrl },
                            },
                            update: apolloUpdateHelper({
                              responseField: 'stakeholderDefinitionUpdate',
                              query: StakeholderDefinitionsDocument,
                              queryVars,
                              queryName: 'stakeholderDefinitions',
                            }),
                          });
                        } catch (err) {
                          console.error(err);
                          alert('Something went wrong');
                        }
                      }}
                      onImageRemoved={() => {
                        try {
                          updateStakeholderDefinition({
                            variables: {
                              id,
                              data: { image: null },
                            },
                            update: apolloUpdateHelper({
                              responseField: 'stakeholderDefinitionUpdate',
                              query: StakeholderDefinitionsDocument,
                              queryVars,
                              queryName: 'stakeholderDefinitions',
                            }),
                          });
                        } catch (err) {
                          console.error(err);
                          alert('Something went wrong');
                        }
                      }}
                      onTextChange={(newTitle) => {
                        if (newTitle === title) return;
                        try {
                          updateStakeholderDefinition({
                            variables: {
                              id,
                              data: { image, title: newTitle },
                            },

                            update: apolloUpdateHelper({
                              responseField: 'stakeholderDefinitionUpdate',
                              query: StakeholderDefinitionsDocument,
                              queryVars,
                              queryName: 'stakeholderDefinitions',
                            }),
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      focus={focused === id}
                      textValue={title}
                      setDisableCreateButton={setDisableCreateButton}
                      isLastInList={id === lastDefId}
                    />
                  </div>
                </TooltipWrapper>
              </div>
            );
          })}
        </div>
      </TabGroup>
      {isLead ? (
        <StyledButtonRound
          iconName="Add patient"
          btnClassName="cypress-stakeholder-def-add"
          level="secondary"
          size="large"
          disabled={disableCreateButton}
          onClick={async () => {
            if (!isLead) return;
            setDisableCreateButton(true);
            const res = await createStakeholderDefinition({
              variables: {
                data: {
                  stakeholder,
                  image: '',
                  title: '',
                  strategy: Number(strategyId),
                  drug: Number(drugId),
                },
              },
              update: apolloCreateHelper({
                responseField: 'stakeholderDefinitionCreate',
                query: StakeholderDefinitionsDocument,
                queryVars,
                queryName: 'stakeholderDefinitions',
              }),
            });
            setFocused(res?.data?.stakeholderDefinitionCreate?.id);
            setDisableCreateButton(false);
          }}
        />
      ) : null}
    </>
  );
};
