import {
  BodySmall,
  ButtonLabel,
  ButtonPill,
  Heading1,
  Heading3,
  Icon,
  Subtitle1,
} from 'components/shared';
import GradientModal, { Wrapper } from 'components/shared/GradientModal';
import { colors } from 'constants/index';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { PageWrapper } from 'containers/StrategyCreationPage';
import { Navbar } from 'components/Navbar';
import { useDrugsQuery, useStrategyWithDrugQuery } from 'data/graphql/generated';
import ErrorPage from 'components/ErrorPage';
import { checkIfFullNmblr } from 'containers/Strategies';

const StyledPageWrapper = styled(PageWrapper)`
  ${Wrapper} {
    &::before {
      background: none;
    }
  }

  padding: 50px 15px;
`;

const CTAWrapper = styled.div`
  display: flex;
  margin-top: 30px;

  & > *:first-child {
    margin-right: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  margin: 30px auto;

  position: relative;
`;

const StyledH1 = styled(Heading1)`
  margin-top: 10px;

  background-image: linear-gradient(134deg, #3c4ec5, #be40ae);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;

const StyledGradientModal = styled(GradientModal)`
  border: none;
  border-radius: 0;
  max-width: 1164px;
  border-radius: 5px;
  margin: 30px auto;

  @media ${device.tabletMin} {
    border: 1px solid rgba(20, 20, 39, 0.1);
  }
`;

interface Props {
  failedInvites: boolean;
}

const StrategyConfirmCreate = ({ failedInvites }: Props) => {
  const history = useHistory();
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();

  const { data: strategy, loading, error } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'no-cache',
  });

  const { data: drugData } = useDrugsQuery({
    variables: { where: { id: Number(drugId) } },
    fetchPolicy: 'network-only',
  });

  const ProductOnDrugs = drugData?.drugs?.items?.filter(
    (e) => e.id === Number(drugId)
  )[0]?.ProductOnDrugs;

  const miniFeatureSet = useMemo(() => {
    if (ProductOnDrugs) {
      return ProductOnDrugs?.filter(i => i.drugId === Number(drugId)).flatMap((f: any) => {
        if (f.Product?.features)
          return f.Product?.features?.map((g: any) => g.featureSet);
        return [];
      });
    }
    return [];
  }, [ProductOnDrugs, drugId]);

  if (loading) return <div>Loading...</div>;

  if (error) return <ErrorPage errorType="generic" />;

  if (!strategy?.strategy || !strategy?.strategy?.drug)
    return <ErrorPage errorType="unpermitted" />;

  const { startYear, endYear, drug } = strategy?.strategy;

  return (
    <StyledPageWrapper>
      <Navbar
        disableSecondary
        exclude={['drugLink', 'filesLink', 'strategyLink']}
      />

      <StyledGradientModal>
        <ContentWrapper>
          <Subtitle1 color={colors.greyDark}>Strategy created</Subtitle1>
          <StyledH1>{drug.name}</StyledH1>
          <Heading3>
            {startYear} - {endYear}
          </Heading3>
          <CTAWrapper>
            <ButtonPill
              text="Back to overview"
              onClick={() => {
                history.push('/');
              }}
              level="secondary"
            ></ButtonPill>
            <ButtonPill
              text="Develop strategy"
              onClick={() => {
                if (!checkIfFullNmblr(miniFeatureSet)) {
                  history.push(`/d/${drugId}/strategy/${strategyId}/welcome`);
                } else history.push(`/d/${drugId}/strategy/${strategyId}`);
              }}
            ></ButtonPill>
          </CTAWrapper>
          {failedInvites ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                margin: '25px 15px 0',
              }}
            >
              <Icon name="Alert" size={30} color={colors.darkRed} />
              <div>
                <BodySmall style={{ display: 'inline' }} color={colors.darkRed}>
                  {
                    'Some invites could not be sent due to an error. Try resending invites from the '
                  }
                </BodySmall>
                <ButtonLabel
                  style={{ display: 'inline', pointerEvents: 'all' }}
                  color={colors.darkRed}
                  onClick={() => {
                    history.push(`/d/${drugId}/strategy/${strategyId}/people`);
                  }}
                >
                  Collaborators
                </ButtonLabel>
                <BodySmall style={{ display: 'inline' }} color={colors.darkRed}>
                  {' page'}
                </BodySmall>
              </div>
            </div>
          ) : null}
        </ContentWrapper>
      </StyledGradientModal>
    </StyledPageWrapper>
  );
};

export default StrategyConfirmCreate;