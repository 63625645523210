import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';

import { colors } from '../constants';
import { Caption, StepButton, Subtitle2 } from './shared';
import useDesktop from 'hooks/useDesktop';
import { device } from 'utils/breakpoints';
import { HelpIcon } from 'components/HelpIcon';
import { useDrugsQuery } from 'data/graphql/generated';

const HelpIconContainer = styled.div`
  position: absolute;
  right: -35px;
`;

const StyledLeftNavButton = styled(StepButton)``;

const TitleWrapper = styled(Subtitle2)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledRightNavButton = styled(StepButton)``;

export const CurrentPageText = styled.div`
  display: grid;
  place-content: center;
  height: 30px;
  border-radius: 5px;
  background: ${colors.cream};
  padding: 0px 10px;
  margin: 0;

  @media ${device.tabletMin} {
    padding: 0px 15px;
    margin: 0 4px;
  }

  font-weight: 400;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  cursor: default;

  @media screen and (max-width: 944px) {
    ${CurrentPageText} {
      order: -1;
      margin-right: 10px;
    }
    ${StyledLeftNavButton} {
      margin-left: auto;
    }
    ${StyledRightNavButton} {
      margin-left: 5px;
    }
  }
`;

const StepNumber = styled(Subtitle2)`
  color: ${colors.greyDark};
  margin-right: 4px;
`;

interface Props {
  title?: string;
  url?: string;
  onClick?(): void;
  prev: { url: string; title: string };
  next: { url: string; title: string };
  hideArrows?: boolean;
  stepNumber?: string;
  className?: string;
}

export const PageNav: React.FC<Props> = ({
  title,
  prev,
  next,
  onClick,
  hideArrows,
  children,
  stepNumber,
  className,
}) => {

  const { drugId } = useParams<{
    drugId: string;
  }>();

  const { data: drugData } = useDrugsQuery({
    variables: {
      where: {
        id: Number(drugId),
      },
    },
    fetchPolicy: 'network-only',
  });

  const possibleDrugs = drugData?.drugs?.items;
  const miniFeatureSet = useMemo(() => {
    if (possibleDrugs) {
      return possibleDrugs.filter(i => i.id === Number(drugId)).flatMap((p: any) =>
        p.ProductOnDrugs?.flatMap((f: any) =>
          f.Product?.features?.map((g: any) => g.featureSet)
        )
      );
    }
    return [];
  }, [drugId, possibleDrugs]);

  const checkFeatureSet = (next: any) => {
    const lastPartOfUrl = next?.url?.split('/').pop(); // This will get "1_3" from "/d/3/strategy/3/1_3"
    const featureToCheck = lastPartOfUrl ? lastPartOfUrl.replace(/_/g, '.') : '';
    if (miniFeatureSet.includes(featureToCheck)) return true;
    return false
  };

  const history = useHistory();
  const isDesktop = useDesktop();

  return title ? (
    <Wrapper className={className}>
      {!hideArrows ? (
        <StyledLeftNavButton
          disabled={!prev.url || !checkFeatureSet(prev)}
          onClick={() => {
            prev.url && history.push(prev.url);
          }}
          tooltip={!isDesktop ? '' : 'Previous step: ' + prev.title}
          direction="l"
        />
      ) : null}
      <CurrentPageText>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {stepNumber && <StepNumber>{stepNumber}</StepNumber>}
          {title.split('||').map((val, index) => {
            if (index === 1) {
              return (
                <span key={index} style={{ margin: '0 5px' }}>
                  <Caption color={colors.black}>{val}</Caption>
                </span>
              );
            }
            return (
              <TitleWrapper key={index} color={colors.black}>
                {val}
              </TitleWrapper>
            );
          })}
        </div>
      </CurrentPageText>
      {!hideArrows ? (
        <StyledRightNavButton
          direction="r"
          tooltip={!isDesktop ? '' : 'Next step: ' + next.title}
          onClick={() => {
            next.url && history.push(next.url);
          }}
          disabled={!next.url || !checkFeatureSet(next)}
        />  
      ) : null}
      {children}

      {isDesktop ? (
        <HelpIconContainer>
          <HelpIcon />
        </HelpIconContainer>
      ) : null}
    </Wrapper>
  ) : null;
};
