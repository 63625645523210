import {
  BodySmall,
  CheckCircle,
  Collaboration,
  Subtitle2,
  Tooltip,
  ButtonLabel,
  Icon,
  TabGroup,
} from 'components/shared';
import { colors } from 'constants/index';
import {
  createRef,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import styled from 'styled-components/macro';
import { TreatmentIcon } from './TreatmentIcon';

// https://github.com/tsayen/dom-to-image
// This allows us to convert the html to an Image, and allows you to style the exported Component
// Works on Safari
// import domtoimage from 'dom-to-image';
import {
  CompetitiveLandscapeOpportunityFragment,
  CompetitiveLandscapeOpportunityUpdateMutationFn,
  useCompetitiveLandscapeOpportunityUpdateMutation,
  CompetitorFragment,
  PostItGroupFragment,
  Stakeholder,
  CompetitiveLandscapeRatingFragment,
  CompetitiveLandscapeOpportunity,
  useStrategyWithDrugQuery,
  } from 'data/graphql/generated';
import { average } from 'utils/average';
import { useAuthContext } from 'contexts/AuthContext';
import ReactTooltip from 'react-tooltip';
import { useWidth } from 'hooks/useWidth';
import { EvaluationGraphsCompetitor } from './EvaluationGraphsCompetitor';
import { EvaluationGraphsStakeholder } from './EvaluationGraphsStakeholder';
import { GraphScroller } from './GraphScroller';
import {
  DistributionModal,
  DistributionModalProps,
  DistributionModalValues,
} from './DistributionModal';
import lodash, { throttle } from 'lodash';
import { ErrorModal } from 'components/ErrorModal';
import { Loading } from 'components/Loading';
import { device } from 'utils/breakpoints';
import { Image } from './CompetitorTab';
import { verifyUserRole } from 'utils/verifyUserRole';
import { IconName } from 'types';

const GraphsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  height: 100%;
`;

const TitleWrapper = styled.div<{ showDistribution: boolean }>`
  min-width: 260px;
  max-width: 260px;
  background: ${colors.white};
  border-radius: 5px 0px 0px 5px;

  @media (max-width: 714px) {
    max-width: none;
    border-radius: ${({ showDistribution }) =>
      showDistribution ? '5px' : '5px 5px 0px 0px'};
  }
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin: 0 auto 0 5px;
  }
`;

const InnerWrapper = styled.div`
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  gap: 5px;
`;

const Row = styled.div<{ showDistribution: boolean }>`
  min-height: 125px;
  background: transparent;

  display: flex;
  gap: 2px;

  overflow: hidden;

  @media (max-width: 714px) {
    flex-direction: column;
    gap: ${({ showDistribution }) => (showDistribution ? '5px' : '2px')};

    height: auto;
  }
`;

const containerHeight = 82;

export const CompetitorTitleContainer = styled.div`
  min-width: 240px;
  height: ${containerHeight}px;
  background: ${colors.white};
  padding: 5px 5px 7px;
  border-radius: 5px 5px 0 0;

  @media ${device.desktopMin} {
    max-width: 240px;
  }
`;

export const CompetitorTitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CompetitorRating = styled.div`
  background: ${colors.purple10};
  margin-top: 5px;
  border-radius: 15px;
  height: 25px;
  text-align: center;
  padding-top: 3px;
  font-size: 15px;

  span {
    color: ${colors.black70};
  }
`;

export const CompetitorImage = styled(Image)<{ imageURL: string }>`
  margin-right: 5px;
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
`;

export const CompetitorTitle = styled(ButtonLabel)`
  margin-right: 10px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  pointer-events: none;
`;

export const StarIcon = styled(Icon)`
  opacity: 0.5;
  margin-right: -5px;

  svg {
    margin-left: -10px;
  }
`;

function getCompetitorRatingColor(hasRating: boolean, score: number): string {
  if (!hasRating) return colors.white;
  if (score <= 2) return colors.purple20;
  if (score <= 3) return colors.purple15;
  if (score <= 4) return colors.purple10;
  if (score <= 5) return colors.purple05;
  return colors.purple05;
}

interface Props {
  groups: PostItGroupFragment[];
  groups2: PostItGroupFragment[];
  stakeholder?: Stakeholder;
  strategyId: number;
  drugId: number;
  stakeholderDefinitionId: number;
  competitorId?: number;
  competitors: CompetitorFragment[];
  showDistribution: boolean;
  selectedRegion: string;
  setShowDistribution: Dispatch<SetStateAction<boolean>>;
}

export const EvaluationStakeHolder = ({
  groups,
  groups2,
  strategyId,
  drugId,
  stakeholder,
  selectedRegion,
  stakeholderDefinitionId,
  competitorId,
  competitors,
  showDistribution,
}: Props) => {
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const isMobile = useWidth() < 715;

  const graphsTitleContainerRef = useRef<HTMLDivElement>(null);

  const [
    desktopGraphsNode,
    setDesktopGraphsNode,
  ] = useState<HTMLDivElement | null>(null);

  const [rowRefs, setRowRefs] = useState<
    Record<number, RefObject<HTMLDivElement>>
  >({});

  const [
    distributionModalRatings,
    setDistributionModalRatings,
  ] = useState<DistributionModalValues | null>(null);

  const { data: strategy, loading } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'no-cache',
  });

  const currentStrategyUsers = strategy?.strategy?.users?.flat() || [];
  const currentDrugUsers = strategy?.strategy?.drug?.users?.flat() || [];

  const currentStrategyAndDrugUniqueUsers = lodash.uniqBy(
    [...currentStrategyUsers, ...currentDrugUsers],
    'id'
  );
  const [
    competitiveLandscapeOpportunityUpdate,
  ] = useCompetitiveLandscapeOpportunityUpdateMutation();

  const [scrollContainerMetrics, setScrollContainerMetrics] = useState({
    scrollWidth: desktopGraphsNode?.scrollWidth || 0,
    offsetWidth: desktopGraphsNode?.offsetWidth || 0,
    scrollLeft: desktopGraphsNode?.scrollLeft || 0,
  });

  const onRefChange = useCallback((node) => {
    setDesktopGraphsNode(node); // trigger re-render on changes
  }, []);

  const [whiteSpaceVisible, setWhiteSpaceVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState('Stakeholder');

  const handleOptionChange = (option: any) => {
    setSelectedOption(option.target.value);
  };
  const allRatings: CompetitiveLandscapeRatingFragment[] = groups2.reduce(
    (acc: CompetitiveLandscapeRatingFragment[], group) => {
      if (group?.competitiveLandscapeRating) {
        return [
          ...acc,
          ...(group.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[]),
        ];
      }
      return acc;
    },
    []
  );

  useLayoutEffect(() => {
    function onScrollHandler() {
      const whiteSpace = document.querySelector('.graph__white-space');

      const whiteSpaceVisible = whiteSpace?.getBoundingClientRect().width;

      setWhiteSpaceVisible(!!whiteSpaceVisible);
      if (desktopGraphsNode)
        setScrollContainerMetrics({
          scrollWidth: desktopGraphsNode.scrollWidth || 0,
          offsetWidth: desktopGraphsNode.offsetWidth || 0,
          scrollLeft: desktopGraphsNode.scrollLeft || 0,
        });
    }

    if (showDistribution && !isMobile) {
      onScrollHandler();

      const throttledScrollHandler = throttle(
        () => {
          onScrollHandler();
        },
        300,
        { trailing: true, leading: false }
      );

      if (desktopGraphsNode) {
        desktopGraphsNode.addEventListener('scroll', throttledScrollHandler);
        window.addEventListener('resize', throttledScrollHandler);
      }

      const element = desktopGraphsNode;

      return () => {
        element?.removeEventListener('scroll', throttledScrollHandler);
        window?.removeEventListener('resize', throttledScrollHandler);
      };
    }
  }, [showDistribution, isMobile, desktopGraphsNode]);

  useEffect(() => {
    // add or remove refs
    setRowRefs(() =>
      groups.reduce((acc, val) => {
        if (!!val) {
          acc[val.id as keyof typeof acc] = createRef();
          return acc;
        }
        return acc;
      }, {} as Record<number, RefObject<HTMLDivElement>>)
    );
  }, [groups]);

  const scrollerHeight =
    //Header
    containerHeight +
    //Row
    Number(graphsTitleContainerRef.current?.offsetHeight || 0) +
    //Gap
    2;

  const scrollAmount =
    //at start
    scrollContainerMetrics.scrollLeft === 0
      ? 261
      : //at end
      scrollContainerMetrics.scrollLeft + scrollContainerMetrics.offsetWidth ===
        scrollContainerMetrics.scrollWidth
      ? 229
      : //in-between
        242;

  function handleScrollerClick(direction: 'left' | 'right') {
    if (!desktopGraphsNode) return;

    desktopGraphsNode.scrollBy({
      left: direction === 'left' ? -scrollAmount : scrollAmount,
      top: 0,
      behavior: (window as any).safari ? 'auto' : 'smooth',
    });
  }

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loading style={{ width: 80, marginTop: 15 }} />
      </div>
    );
  }

  const stakeHolders = [{
    id: 1,
    title: 'Patients',
    color: colors.purple,
    type: Stakeholder.Patient,
    icon: 'Patient'
  },
  {
    id: 2,
    title: 'Healthcare Professionals',
    color: colors.green,
    type: Stakeholder.Healthcare,
    icon: 'HCP'
  },
  {
    id: 3,
    title: 'Payors',
    color: colors.blue,
    type: Stakeholder.Payor,
    icon: 'Dollar'
  }
  ]

  return (
    <div style={{margin: '0 20px '}}>
      <DistributionModal
        companyUsersData={currentStrategyAndDrugUniqueUsers}
        visible={!!distributionModalRatings}
        handleClose={() => {
          setDistributionModalRatings(null);
        }}
        title={distributionModalRatings?.title}
        subtitle={distributionModalRatings?.subtitle}
        ratings={distributionModalRatings?.ratings}
      />

      <Tooltip id={'Evaluation'} effect="float" place="bottom" />

      {/* <Toggle
        value={showDistribution}
        onChange={() => {
          setShowDistribution(!showDistribution);
        }}
        label="Show competitor distribution"
      /> */}

      {isMobile && (
        <div style={{ marginTop: 20 }}>
          <TabGroup componentName="CompetitveLandscapeCompetitor">
            {competitors.map((competitor) => {
              const compRatings = allRatings
                .slice()
                .filter((rating) => rating.competitorId === competitor.id);

              const compAverageScore = average(
                compRatings.map((val) => val.score)
              ).toFixed(1);

              return (
                //Competitor Headers - Mobile
                <CompetitorTitleContainer
                  key={competitor.id}
                  style={{ marginRight: 2 }}
                >
                  <CompetitorTitleWrapper>
                    {competitor.image ? (
                      <CompetitorImage imageURL={competitor.image} />
                    ) : (
                      <TreatmentIcon />
                    )}
                    <CompetitorTitle>{competitor.title}</CompetitorTitle>

                  </CompetitorTitleWrapper>
                  <CompetitorRating
                    style={{
                      backgroundColor: getCompetitorRatingColor(
                        compRatings.length > 0,
                        +compAverageScore
                      ),
                    }}
                  >
                    {compRatings.length > 0 ? (
                      <>
                        <span>Average score</span> {+compAverageScore}
                      </>
                    ) : (
                      'No ratings'
                    )}
                  </CompetitorRating>
                </CompetitorTitleContainer>
              );
            })}
          </TabGroup>
        </div>
      )} 

      {/* Export */}
      {/* <ButtonPill
        level="export"
        text="export"
        onClick={() => {
          domtoimage
            //@ts-ignore
            .toJpeg(document.getElementById('graphsTitleContainer'), {
              quality: 0.95,
              bgcolor: colors.black05,
              style: {
                background: colors.black05,
                overflow: 'hidden',
              },
            })
            .then(function (dataUrl) {
              var link = document.createElement('a');
              link.download = 'my-image-name.jpeg';
              link.href = dataUrl;
              link.click();
            });
        }}
      /> */}
      <div
        style={{
          display: 'flex',
          marginTop: '20px'
        }}
      >
        <div
          ref={graphsTitleContainerRef}
          id="graphsTitleContainer"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 15 : 2,
            width: isMobile ? '100%' : 'auto',
          }}
        >
        <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '99%',
          marginTop: '40px'
        }}
      >
        <label 
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
            padding: '10px 5px',
            border: `.5px solid ${selectedOption === 'Stakeholder' ? '#6200EE' : '#AAA'}`,
            borderRadius: '5px 0px 0px 5px',
            backgroundColor: selectedOption === 'Stakeholder' ? '#F3E5F5' : '#FFF',
          }}
        >
          <input
            type="radio"
            value="Stakeholder"
            checked={selectedOption === 'Stakeholder'}
            onChange={handleOptionChange}
            style={{ display: 'none' }}
          />
          <span
            style={{
              display: 'inline-block',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              border: `2px solid ${selectedOption === 'Stakeholder' ? '#6200EE' : '#AAA'}`, // Outer border with purple when Stakeholder is selected
              marginRight: '8px',
              position: 'relative', // Relative positioning for inner circle
            }}
          >
            {selectedOption === 'Stakeholder' && (
              <span
                style={{
                  display: 'inline-block',
                  width: '8px', // Smaller inner circle
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: '#6200EE', // Purple fill
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', // Centering the inner circle
                }}
              ></span>
            )}
          </span>
          Stakeholder
        </label>

        <label
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '10px 5px',
            border: `.5px solid ${selectedOption === 'Competitor' ? '#6200EE' : '#AAA'}`,
            borderRadius: '0px 5px 5px 0px',
            backgroundColor: selectedOption === 'Competitor' ? '#F3E5F5' : '#FFF',
          }}
        >
          <input
            type="radio"
            value="Competitor"
            checked={selectedOption === 'Competitor'}
            onChange={handleOptionChange}
            style={{ display: 'none' }}
          />
          <span
            style={{
              display: 'inline-block',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              border: `2px solid ${selectedOption === 'Competitor' ? '#6200EE' : '#AAA'}`, // Outer border with purple when Competitor is selected
              marginRight: '8px',
              position: 'relative', // Relative positioning for inner circle
            }}
          >
            {selectedOption === 'Competitor' && (
              <span
                style={{
                  display: 'inline-block',
                  width: '8px', // Smaller inner circle
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: '#6200EE', // Purple fill
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', // Centering the inner circle
                }}
              ></span>
            )}
          </span>
          Competitor
        </label>
      </div>

          {groups.map((group) => {
            const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
            const competitiveLandscapeOpportunities = group?.competitiveLandscapeOpportunities as CompetitiveLandscapeOpportunity[];

            const ratings = competitiveLandscapeRating
              .slice()
              .filter(
                (rating) =>
                  rating.stakeholderDefinitionId === stakeholderDefinitionId
              );

            const averageScore = average(
              ratings.map((val) => val.score)
            ).toFixed(1);

            const scoreDistribution = createScoreDistribution(ratings);

            const selectedOpportunity = competitiveLandscapeOpportunities.find(
              (opportunity) => {
                return (
                  opportunity.stakeholderDefinitionId ===
                    stakeholderDefinitionId &&
                  opportunity.stakeholder === stakeholder
                );
              }
            );

            return (
              <Row
                key={group.id}
                ref={rowRefs[group.id]}
                showDistribution={showDistribution}
              >
                <TitleContainer
                  competitiveLandscapeOpportunityUpdate={
                    competitiveLandscapeOpportunityUpdate
                  }
                  group={group}
                  selectedOpportunity={selectedOpportunity}
                  isLead={isLead}
                  showDistribution={showDistribution}
                />

                {/* Graphs for mobile view */}
                {isMobile && (
                  <EvaluationGraphsStakeholder
                  isLead={isLead}
                  rowRefs={rowRefs}
                  key={group.id}
                  isMobile={false}
                  selectedRegion={selectedRegion}
                  showDistribution={showDistribution}
                  competitors={competitors}
                  stakeHolders={stakeHolders}
                  stakeholderDefinitionId={stakeholderDefinitionId}
                  group={group}
                  groups2={groups2}
                  overallAverageScore={+averageScore}
                  scoreDistribution={scoreDistribution}
                  setDistributionModalRatings={({
                    subtitle,
                    ratings,
                  }: Omit<DistributionModalProps, 'title'>) => {
                    setDistributionModalRatings({
                      title: group.title,
                      subtitle,
                      ratings,
                    });
                  }}
                />
                )}
              </Row>
            );
          })}
        </div>

        {/* Graphs for Desktop view */}
        {!isMobile && (
          <>
            {showDistribution && (
              // Left scroll
              <GraphScroller
                hide={() => {
                  const scrolledPastStart =
                    scrollContainerMetrics.scrollLeft === 0;

                  return scrolledPastStart;
                }}
                height={scrollerHeight}
                onClick={() => handleScrollerClick('left')}
              />
            )}

            {selectedOption === 'Stakeholder' ? 
            <GraphsWrapper className="GraphsWrapper" ref={onRefChange}>
              {showDistribution && (
                <div
                  style={{
                    display: 'flex',
                    gap: 2,
                    flex: 'none',
                  }}
                >
                  {stakeHolders.map((stakeholder) => {
                    const compRatings = allRatings
                      .slice()
                      .filter(
                        (rating) => rating.stakeholder === stakeholder.type && rating.stakeholderDefinitionId === stakeholderDefinitionId
                     && (selectedRegion === 'global' || rating.region === selectedRegion)

                      );

                    const compAverageScore = average(
                      compRatings.map((val) => val.score)
                    ).toFixed(1);

                    return (
                      //Competitor Headers - Desktop
                      <CompetitorTitleContainer key={stakeholder.id}>
                        <CompetitorTitleWrapper>
                          <Icon size={25} name={stakeholder.icon as IconName}  color={stakeholder.color}/>

                          {/* {stakeholder?.image ? (
                            <CompetitorImage imageURL={stakeholder?.image} />
                          ) : (
                            <TreatmentIcon />
                          )} */}
                          <CompetitorTitle>{stakeholder.title}</CompetitorTitle>

                          {/* {competitor?.CompetitorDetails?.some(
                            (s) =>
                              s?.stakeholder === stakeholder &&
                              s.stakeholderDefinitionId ===
                                stakeholderDefinitionId &&
                              !!s.isStarred
                          )} */}
                        </CompetitorTitleWrapper>
                        <CompetitorRating
                          style={{
                            backgroundColor: getCompetitorRatingColor(
                              compRatings.length > 0,
                              +compAverageScore
                            ),
                          }}
                        >
                          {compRatings.length > 0 ? (
                            <>
                              <span>Average score</span> {+compAverageScore}
                            </>
                          ) : (
                            'No ratings'
                          )}
                        </CompetitorRating>
                      </CompetitorTitleContainer>
                    );
                  })}
                </div>
              )}

              {groups.map((group) => {
                const filteredGroups = groups2.filter(g => g.title === group.title);
                let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

                // Iterate over each filtered group to collect ratings
                filteredGroups.forEach(filteredGroup => {
                  const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
                
                  // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
                  const ratings = competitiveLandscapeRating2.filter(
                    (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId
                     && (selectedRegion === 'global' || rating.region === selectedRegion)
                  );
                
                  allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
                });
                

                const averageScore = average(
                  allRatings2.map((val) => val.score)
                ).toFixed(1);

                const scoreDistribution = createScoreDistribution(allRatings2);

                return (
                  !isMobile && (
                    <EvaluationGraphsStakeholder
                      isLead={isLead}
                      rowRefs={rowRefs}
                      key={group.id}
                      isMobile={false}
                      selectedRegion={selectedRegion}
                      showDistribution={showDistribution}
                      competitors={competitors}
                      stakeHolders={stakeHolders}
                      stakeholderDefinitionId={stakeholderDefinitionId}
                      group={group}
                      groups2={groups2}
                      overallAverageScore={+averageScore}
                      scoreDistribution={scoreDistribution}
                      setDistributionModalRatings={({
                        subtitle,
                        ratings,
                      }: Omit<DistributionModalProps, 'title'>) => {
                        setDistributionModalRatings({
                          title: group.title,
                          subtitle,
                          ratings,
                        });
                      }}
                    />
                  )
                );
              })}
            </GraphsWrapper>
            :
             <GraphsWrapper className="GraphsWrapper" ref={onRefChange}>
              {showDistribution && (
                <div
                  style={{
                    display: 'flex',
                    gap: 2,
                    flex: 'none',
                  }}
                >
                  {competitors.map((competitor) => {
                    const compRatings = allRatings
                      .slice()
                      .filter(
                        (rating) => rating.competitorId === competitor.id && rating.stakeholderDefinitionId === stakeholderDefinitionId
                     && (selectedRegion === 'global' || rating.region === selectedRegion)

                      );

                    const compAverageScore = average(
                      compRatings.map((val) => val.score)
                    ).toFixed(1);

                    return (
                      //Competitor Headers - Desktop
                      <CompetitorTitleContainer key={competitor.id}>
                        <CompetitorTitleWrapper>
                          {competitor.image ? (
                            <CompetitorImage imageURL={competitor.image} />
                          ) : (
                            <TreatmentIcon />
                          )}
                          <CompetitorTitle>{competitor.title}</CompetitorTitle>

                        </CompetitorTitleWrapper>
                        <CompetitorRating
                          style={{
                            backgroundColor: getCompetitorRatingColor(
                              compRatings.length > 0,
                              +compAverageScore
                            ),
                          }}
                        >
                          {compRatings.length > 0 ? (
                            <>
                              <span>Average score</span> {+compAverageScore}
                            </>
                          ) : (
                            'No ratings'
                          )}
                        </CompetitorRating>
                      </CompetitorTitleContainer>
                    );
                  })}
                </div>
              )}

              {groups.map((group) => {
                const filteredGroups = groups2.filter(g => g.title === group.title);
                let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

                // Iterate over each filtered group to collect ratings
                filteredGroups.forEach(filteredGroup => {
                  const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
                
                  // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
                  const ratings = competitiveLandscapeRating2.filter(
                    (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId
                    && (selectedRegion === 'global' || rating.region === selectedRegion)

                  );
                
                  allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
                });
                

                const averageScore = average(
                  allRatings2.map((val) => val.score)
                ).toFixed(1);

                const scoreDistribution = createScoreDistribution(allRatings2);

                return (
                  !isMobile && (
                    <EvaluationGraphsCompetitor
                      isLead={isLead}
                      rowRefs={rowRefs}
                      key={group.id}
                      isMobile={false}
                      showDistribution={showDistribution}
                      competitors={competitors}
                      group={group}
                      selectedRegion={selectedRegion}
                      groups2={groups2}
                      stakeholderDefinitionId={stakeholderDefinitionId}
                      competitorId={competitorId}
                      overallAverageScore={+averageScore}
                      scoreDistribution={scoreDistribution}
                      setDistributionModalRatings={({
                        subtitle,
                        ratings,
                      }: Omit<DistributionModalProps, 'title'>) => {
                        setDistributionModalRatings({
                          title: group.title,
                          subtitle,
                          ratings,
                        });
                      }}
                    />
                  )
                );
              })}
            </GraphsWrapper>
          }

            {showDistribution && (
              <GraphScroller
                height={scrollerHeight}
                right
                hide={() => {
                  const roomToScroll =
                    scrollContainerMetrics.scrollWidth >=
                    scrollContainerMetrics.offsetWidth;

                  const scrolledToEnd =
                    scrollContainerMetrics.scrollLeft ===
                    scrollContainerMetrics.scrollWidth -
                      scrollContainerMetrics.offsetWidth;

                  return whiteSpaceVisible || (roomToScroll && scrolledToEnd);
                }}
                onClick={() => handleScrollerClick('right')}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const TitleContainer = ({
  isLead,
  competitiveLandscapeOpportunityUpdate,
  group,
  selectedOpportunity,
  showDistribution,
}: {
  isLead: boolean;
  group: PostItGroupFragment;
  competitiveLandscapeOpportunityUpdate: CompetitiveLandscapeOpportunityUpdateMutationFn;
  selectedOpportunity?: CompetitiveLandscapeOpportunityFragment;
  showDistribution: boolean;
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [isApproved, setIsApproved] = useState(selectedOpportunity?.approved);
  const [errorModal, setErrorModal] = useState(false);
  const closeErrorModal = () => setErrorModal(false);

  useEffect(() => {
    setIsApproved(selectedOpportunity?.approved);
  }, [selectedOpportunity?.approved]);

  async function toggleOpportunityApproved() {
    if (!selectedOpportunity) return alert('No opportunity id provided');

    setIsApproved(!isApproved);

    try {
      await competitiveLandscapeOpportunityUpdate({
        variables: {
          id: selectedOpportunity.id,
          data: {
            strategyId: group.strategyId,
            approved: !isApproved,
          },
        },
      });
    } catch (err) {
      setIsApproved(isApproved);

      if (
        err instanceof Error &&
        err.message.includes('Cannot remove this opportunity')
      ) {
        setErrorModal(true);
      } else {
        alert(`Error: Couldn't update this Opportunity`);
      }
    }
  }

  return (
    <TitleWrapper showDistribution={showDistribution}>
      <ErrorModal
        title="Cannot remove this opportunity"
        text="Content in later steps depends on this outcome as an opportunity. Remove content and try again."
        visible={errorModal}
        handleClose={closeErrorModal}
      />

      <InnerWrapper>
        <TopRow>
          <div
            data-for={'Evaluation'}
            data-tip={isLead ? '' : 'Only Leads can edit'}
            data-offset="{'top': 0}"
          >
            <CheckCircle
              onClick={toggleOpportunityApproved}
              className={`cypress-competitive-landscape-opportunity_${!!isApproved}`}
              isDisabled={!isLead}
              complete={!!isApproved}
            />
          </div>
          <BodySmall color={isApproved ? colors.black : colors.greyDark}>
            Opportunity
          </BodySmall>
          <Collaboration collaboration={selectedOpportunity?.collaboration} />
        </TopRow>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Subtitle2>{group.title}</Subtitle2>
        </div>
      </InnerWrapper>
    </TitleWrapper>
  );
};

export function createScoreDistribution(ratings: { score: number }[]) {
  return ratings.reduce((acc, val) => {
    if (acc[val.score]) {
      acc[val.score] = acc[val.score] + 1;
    } else {
      acc[val.score] = 1;
    }

    return acc;
  }, {} as Record<number, number>);
}
