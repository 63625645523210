import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components/macro';
import { BodySmall } from '.';

export const Wrapper = styled.div<{
  background?: string;
  hoverBgColor?: string;
  className?: string;
}>`
  background: ${({ background }) => background && background};
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 40px;
  padding: 5px 20px 5px 5px;
  cursor: pointer;
  position: relative;

  svg {
    transform: translateX(0);
    transition: transform 0.2s ease-out;
  }

  &:hover {
    background: ${({ hoverBgColor }) => hoverBgColor && hoverBgColor};

    svg {
      transform: translateX(3px);
    }
  }

  ${BodySmall} {
    z-index: 2;
  }
`;

const IconContainer = styled.div<{ background?: string; color?: string }>`
  border-radius: 50%;
  background: ${({ background }) =>
    background ? background : colors.purplePinkGradient};
  color: ${({ color }) => (color ? color : colors.white)};
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

interface Props {
  onClick?: () => void;
  iconColor?: string;
  iconBgColor?: string;
  bgColor?: string;
  hoverBgColor?: string;
  textColor?: string;
  text: string;
  className?: string;
}
export const SlidingButtonArrow: React.FC<Props> = ({
  onClick,
  iconColor,
  iconBgColor,
  bgColor,
  textColor,
  text,
  hoverBgColor,
  className,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      background={bgColor}
      hoverBgColor={hoverBgColor}
    >
      <IconContainer background={iconBgColor} color={iconColor}>
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.8995 14.9497L21.4298 15.48L21.4298 14.4193L20.8995 14.9497ZM15.4194 10.5302L20.3691 15.48L21.4298 14.4193L16.48 9.46959L15.4194 10.5302ZM20.3691 14.4193L15.4194 19.3691L16.48 20.4297L21.4298 15.48L20.3691 14.4193ZM20.8995 14.1997L8.99996 14.1997L8.99996 15.6997L20.8995 15.6997L20.8995 14.1997Z"
            fill={iconColor || 'currentColor'}
          />
        </svg>
      </IconContainer>
      <BodySmall color={textColor || colors.black}>{text}</BodySmall>
    </Wrapper>
  );
};
