import { colors } from 'constants/index';
import styled from 'styled-components/macro';
import { BodySmall } from '../TextStyles';

export const GradientBG = styled.div`
  padding: 1px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 115px;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const ReadonlyText = styled(BodySmall)`
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-top: 10px;
  margin-bottom: 5px;
`;

interface ReadonlyProps {
  readonly: boolean;
  data: any;
  step?: never;
  drugId?: never;
  strategyId?: never;
  createObservation?: never;
  updateObservation?: never;
  deleteObservation?(): void;
  setUpdating?: never;
  preview?: never;
  stakeholder?: never;
  removePreview?(): void;
  openObservationDropdown?: never;
  setOpenObservationDropdown?: never;
  disableCollaboration?: boolean;
  checked?: boolean;
  onClick?(): void;
}

export const Outcomes = ({
  data,
  readonly,
  onClick,
}: any | ReadonlyProps) => {
  if (readonly && data) {
    return (
      <GradientBG onClick={onClick}>
        <Wrapper className="cypress-observation">
          <ReadonlyText>{data?.title}</ReadonlyText>
        </Wrapper>
      </GradientBG>
    );
  }

  return null;
};
