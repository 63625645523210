import { colors, zIndex } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { ButtonPill, Icon } from '.';
import { BodyNormal, BodySmall, ButtonLabel, Subtitle2 } from './TextStyles';
import { device } from 'utils/breakpoints';
import ReactDOM from 'react-dom';
import { verifyUserRole } from 'utils/verifyUserRole';
import { DraggableOutcomes } from './Outcomes/DraggableOutcomes';
import { Alignment, PostItCardType, Stakeholder, Step, useAlignmentCreateMutation, usePostItGroupsWithCardsQuery } from 'data/graphql/generated';
import { OutcomesDragLayer } from './Outcomes/OutcomesDragLayer';
import { TextareaWrapper } from 'components/4-2-medical-strategy/shared/feature-key-events-sidebar/src/lib/ui/KeyEvent.styles';
import { DeleteConfirmation, DeleteConfirmationBody, DeleteConfirmationFooter, StyledDeleteButton } from 'components/Insight';
import { usePostItCards, usePostItCardsPayload } from 'hooks/usePostItCards';
import maxBy from 'lodash/maxBy';
import { TargetBox } from './Outcomes/TargetBox';
import { PostItGroupAndCards } from 'types';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { polling } from 'constants/index';

interface Props {
  open: boolean;
  setOpen(state: boolean): void;
  groups: PostItGroupAndCards[] | undefined;
  selectedOutcomes: PostItGroupAndCards | undefined;
  loading: boolean;
  offsetTopOverride?: number;
  readonly?: boolean;
  addCard: usePostItCardsPayload['createCard'];
  removeCard: usePostItCardsPayload['removeCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  refetch: () => void;
  refetchGroupsWithCards: () => void;
}

const StyledPostItsEmpty = styled(PostItsEmpty)`
  background: var(--white-50, rgba(255, 255, 255, 0.50));
  height: 100%;
  @media ${device.tabletMin} {
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }

  @media ${device.mobile} {
    padding-bottom: 88px;
    height: auto;
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }
`;

const Wrapper = styled.div<{
  open: boolean;
  offsetTop: number;
  offsetTopOverride: number | undefined;
}>`
  display: flex;
  user-select: none;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: ${({ offsetTopOverride = 0 }) =>
    `calc(100vh -  50px - ${offsetTopOverride}px)`};
  top: 50px;
  background: linear-gradient(
      0deg,
      rgba(20, 20, 39, 0.05),
      rgba(20, 20, 39, 0.05)
    ),
    #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  right: ${({ open }) => (open ? '0px' : '-100%')};
  bottom: ${({ offsetTopOverride }) => (offsetTopOverride ? 0 + 'px' : 'auto')};

  z-index: ${zIndex.observationsMenu};

  transition: all 0.3s;

  @media ${device.tabletMin} {
    width: 430px;
  }
`;

export const GradientBG = styled.div<{
  selected?: boolean;
}>`
  background: ${({ selected }) =>
    selected ? colors.purplePinkGradient : colors.black30a};
  padding: 1px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
  position: relative;
`;

const ContentWrapper = styled.div<{ topMargin: boolean, loading: boolean }>`
  padding: 15px;
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 15px;
  }
  height: 90%;
  overflow: auto;

  .content {
    display: flex;
    flex-direction: column;
    > * + * {
      margin-top: 15px;
    }
  }
  ${({ loading }) => (loading ? 'opacity: .5; cursor: no-drop; pointer-events: none' : '')};
  
  ${Subtitle2} {
  align-items: center;
  display: flex;
  justify-content: center;
  }
  
  margin-top: ${({ topMargin }) => (topMargin ? '15px' : 0)};
  transition: margin 0.1s;
`;

const TextWrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 61px;
  background: ${colors.white};
  padding: 15px 15px 16px 20px;
  display: flex;
  > * + * {
    margin-left: 5px;
  }
  align-items: center;
  > div:last-child {
    cursor: pointer;
    margin-left: auto;
  }
  > p > span {
    color: ${colors.black50};
  }
`;

const StyledComponent = styled.div`

  align-items: center;
  display: inline-flex;

  margin-top: 0;
  &::before,
  &::after {
  margin-left: 5px;
  margin-right: 5px;
    content: '';
    flex: 1 1 0;
    height: 0;
    border: 1px solid rgba(20, 20, 39, 0.70);
  }

  & > span {
    color: rgba(20, 20, 39, 0.70);
    font-size: 14px;
    font-family: 'ABC Favorit Trial';
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
`;

const CardWrapper = styled.div`
display: flex;
align-items: center;
width:100%;

${BodyNormal} {
margin-right: 10px;
}
`

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: string;
  competitorId: string;
}

export const OutcomesMenu = ({
  open,
  setOpen,
  groups,
  loading,
  refetch,
  refetchGroupsWithCards,
  addCard,
  selectedOutcomes,
  removeCard,
  updateCard,
  offsetTopOverride,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [outcomes, setOutcomes] = useState<usePostItCardsPayload | any>(
    () => groups || []
  );
  // const [loadingOutcomes, setIsLoading] = useState(false);
  const [loadingOutcomes, setIsLoadingOutcomes] = useState(false);
  const [includedOutcomes, setIncludedOutcomes] = useState([]);
  const [excludedOutcomes, setExcludedOutcomes] = useState([]);
  const [addOutcomes, setAddOutcomes] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(true);
  const [textValue, setTextValue] = useState('');
  const [card, setCard] = useState<any>(null);
  const [cards, setCards] = useState<any>(null);
  const [createAlignment] = useAlignmentCreateMutation();

  const {
    drugId,
    strategyId,
    stakeholder,
    stakeholderDefinitionId,
  }: URLParams = useParams();
  const {
    items: cardItems,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        
      },
    },
    {
      strategyId: Number(strategyId),
      drugId:  drugId,
      step: Step.Keystakeholders,
      // stakeholder,
      // stakeholderDefinitionId: Number(stakeholderDefinitionId),
      // competitorId: Number(competitorId),
    }
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const {
    data: group,
    refetch: refetch2,
    startPolling,
    stopPolling,
  } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [
    startPolling,
    stopPolling,
  ]);

  // filter groups for "What are the outcomes that matter to" and for Patients, HPC and Payors only
  const mappedGroups = useMemo(() => group?.postItGroups.items.filter(p => p.title.includes('What are the outcomes that matter to') && p.stakeholder !== Stakeholder.Provider && p.stakeholder !== Stakeholder.Policymaker).reduce((acc, val) => {
    return [
      ...acc,
      {
        ...val,
        cards:
          val.cards?.filter((c) => c?.postItGroupId === val.id)
            .sort((cardA, cardB) => {
              return (cardB?.pos || 0) - (cardA?.pos || 0);
            }).map(e => {
              return { ...e, stakeholder: val.stakeholder }
            }) || [],
      },
    ];
  }, [] as PostItGroupAndCards[]), [group])


  useEffect(() => {
    if (!stakeholderDefinitionId) return;
    // Refactor the filter for the outcome based on includes and stakeholder selected
    setCards(mappedGroups?.flatMap(e => e.cards))
    const c = mappedGroups?.flatMap(e => e.cards);
    const filteredIncludedOutcomes: any= c
      ?.filter((i: any) => i.stakeholder === stakeholder && i.include === true && i.stakeholderDefinitionId === +stakeholderDefinitionId)
      .sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime())
    
    const filteredExcludedOutcomes: any = c
    ?.filter((i: any) => i.include === false && i.type === PostItCardType.Outcome)
    .sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime())
    .filter((excludedItem) => 
      filteredIncludedOutcomes && filteredIncludedOutcomes.length > 0 
        ? !filteredIncludedOutcomes.some((includedItem: any) => includedItem.title === excludedItem.title) // Only filter if includedOutcomes is not empty
        : true // If includedOutcomes is empty, don't filter anything
    );

    // Set state with the updated outcomes
    setIncludedOutcomes(filteredIncludedOutcomes)
    setExcludedOutcomes(filteredExcludedOutcomes);
  }, [mappedGroups, group, stakeholder, stakeholderDefinitionId]); 

  useEffect(() => {
    if (groups) {
      const updatedOutcomes = groups?.slice();

      if (updatedOutcomes) {
        setOutcomes(groups);
      }
    }
    refetch2();
  }, [groups, refetch2]);

  useClickOutsideComponent(
    textAreaRef,
    () => {
      if (!!textValue) {
        setShowDeleteButton(false);
        setAddOutcomes(false);
        setTextValue('');
        confirmDelete && setConfirmDelete(false);
      } if (!!card) {
        setCard(null);
        refetch();
        refetchGroupsWithCards();
      }
    },
    ['add-outcomes']
  );

  useClickOutsideComponent(textAreaRef, () => {
    setShowDeleteButton(false);
  });

  useClickOutsideComponent(
    ref,
    () => {
      const selection = window.getSelection();
      const containsChild =
        selection?.focusNode && ref.current?.contains(selection.focusNode);

      !containsChild && setOpen(false);
    },
    ['stakeholderTab', 'stakeholderDefinitions', 'modal']
  );

  if (loading) {
    return null;
  }

  // // TODO: refactor the filter the outcome based on incudes and stakeholder selected
  // const includedOutcomes = cards
  //   ?.filter((i: any) => i.stakeholder === stakeholder && i.include === true && i.stakeholderDefinitionId === +stakeholderDefinitionId)
  //   .sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime())

  // // TODO: refactor the filter the outcome based on excludes and stakeholder selected
  // const excludedOutcomes = cards
  // ?.filter((i: any) => i.include === false && i.type === PostItCardType.Outcome)
  // .sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime())
  // .filter((excludedItem) => 
  //   includedOutcomes && includedOutcomes.length > 0 
  //     ? !includedOutcomes.some((includedItem) => includedItem.title === excludedItem.title) // Only filter if includedOutcomes is not empty
  //     : true // If includedOutcomes is empty, don't filter anything
  // );
  
  const highestCardPos: any = maxBy(outcomes, 'pos') ?? null;

  const checkExists = async(text: string ) => {
    const exists = cardItems.some((item: any) => item.title === text);
    return exists;
  }

  if (!selectedOutcomes) {
    return <></>;
  }
  
  return ReactDOM.createPortal(
    <Wrapper
      ref={ref}
      offsetTopOverride={offsetTopOverride}
      offsetTop={ref.current?.offsetTop || 0}
      open={open}
    >
      <Header>
        <Icon name="Target" color={colors.black} size={30} />
        <Subtitle2>Outcomes</Subtitle2>
        {/* <div>{stakeholder}</div> */}
        <div
          className="cypress-outcomes-close"
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon name="Collapse right" size={30} color={colors.purple} />
        </div>
      </Header>
      {cards ? (
        <>
      {isLead ? (
        <div
          className="add-outcomes"
          style={{ width: 172, padding: '15px 0 0px 15px' }}
        >
          <ButtonPill
          disabled={addOutcomes}
            iconName="Plus"
            text="Add Outcomes"
            onClick={() => {
              setAddOutcomes(true);
              if (outcomes?.length > 0)
                setOutcomes(() => {
                  const newArr = outcomes.slice();
                  return newArr;
                });
            }}
          />
        </div>
      ) : (
        <div style={{ width: '100%', padding: '15px', paddingBottom: 0 }}>
          <BodySmall color={colors.black}>
            Leads create observations to summarise contributions
          </BodySmall>
        </div>
      )}
      <>
        <ContentWrapper topMargin loading={loadingOutcomes}>
          {/* TODO: check if this empty state is still needed
         {outcomes.length <= 0 && (
          <EmptyStateWrapper>
            <Icon
              name="GenericEmptyState"
              size={115}
              height={115}
              color="initial"
            />
            <Subtitle2 color={colors.greyDark}>Cannot add outcomes yet</Subtitle2>
            <BodySmall color={colors.greyDark}>
             Work can only begin when a Lead has created a {stakeholder} types
            </BodySmall>
          </EmptyStateWrapper>
        )} 
         */}

          <StyledComponent>
            <BodyNormal>Includes</BodyNormal>
          </StyledComponent>
          {addOutcomes && (
            <GradientBG selected={false}>
              <TextWrapper>
                <DeleteConfirmation show={false}>
                  <div>
                    <BodyNormal color={colors.black}>
                      Delete this observation?
                    </BodyNormal>
                  </div>
                  <DeleteConfirmationBody>
                    <BodyNormal color={colors.black60}>
                      Any discussion and files will be lost. It will be removed from
                      any insights it is supporting.
                    </BodyNormal>
                  </DeleteConfirmationBody>
                  <DeleteConfirmationFooter>
                    <ButtonLabel
                      onClick={() => {
                        setConfirmDelete(false);
                      }}
                    >
                      Cancel
                    </ButtonLabel>
                    <ButtonLabel
                      className="cypress-observation-delete-confirm"
                      color={colors.red}
                      onClick={async (e) => {
                        e.stopPropagation();
                        setAddOutcomes(false);
                        setConfirmDelete(false);
                        setShowDeleteButton(true);
                      }}
                    >
                      Delete
                    </ButtonLabel>
                  </DeleteConfirmationFooter>
                </DeleteConfirmation>
                <div style={{ display: 'flex' }}>
                  <Icon name="Grip" color={colors.black} size={30} />
                  <TextareaWrapper
                    onClick={e => e.stopPropagation()}
                    ref={textAreaRef}
                    onFocus={() => {
                      if (!isLead) return;
                      setShowDeleteButton(true);
                    }}
                    onChange={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      setTextValue(target.value);
                    }}
                    onBlur={async () => {
                      
                      if (!isLead) return;
                      // create outcomes
                      if (!!textValue) {
                        const exists = await checkExists(textValue);
                        if (exists) {
                          return;
                        }

                        const card = await addCard({
                          postItGroup: (selectedOutcomes.id),
                          pos: highestCardPos?.pos ? Number(highestCardPos?.pos) + 1000 : 1000,
                          title: textValue,
                          ...(Step.Keystakeholders ? { step: Step.Keystakeholders } : undefined),
                          type: PostItCardType.Outcome,
                        });
                        refetchGroupsWithCards();
                        
                        setCard(card);
                        const collaborationId = card?.data?.postItCardCreate?.collaboration?.id ?? null;

                        // create alignment for created outcome
                        if (collaborationId && strategyId) {
                          await createAlignment({
                            variables: {
                              data: { collaborationId, strategyId: Number(strategyId) },
                            },
                            update: (cache, { data }) => {
                              cache.modify({
                                id: cache.identify({
                                  __ref: `Collaboration:${data?.alignmentCreate.collaborationId}`,
                                }),
                                fields: {
                                  alignmentCount(alignment) {
                                    return alignment + 1;
                                  },
                                  alignments(
                                    alignments: Pick<
                                      Alignment,
                                      'id' | 'createdAt' | 'collaborationId'
                                    >[]
                                  ) {
                                    return [...alignments, data?.alignmentCreate];
                                  },
                                },
                              });
                            },
                          });
                          setAddOutcomes(false)
                          setTextValue('')
                          refetch()
                          refetch2();
                          refetchGroupsWithCards();
                        }
                      }
                    }}
                    $text={!!textValue}
                    $textValue={!!textValue}
                    autoFocus
                    rows={1}
                    value={textValue}
                  />
                </div>
              </TextWrapper>
              <StyledDeleteButton
                className="cypress-observation-delete"
                innerRef={deleteButtonRef}
                iconName="Trash"
                level="secondary"
                size="small"
                tooltip=""
                onClick={() => {
                  setConfirmDelete(true);
                  setShowDeleteButton(false);
                  setAddOutcomes(false);
                }}
                show={showDeleteButton}
              />
            </GradientBG>)}

          {/* drag and drop receiver for excluded outcomes */}
          <TargetBox source="excludes" content={(includedOutcomes.length > 0? (
            includedOutcomes.map((postIts, index) => (
              <CardWrapper key={index}>
                <BodyNormal color={colors.purple} style={{width: 15}}>
                  {index + 1} 
                </BodyNormal>
                <DraggableOutcomes
                  setIsLoadingOutcomes={setIsLoadingOutcomes}
                  removeCard={removeCard}
                  data={postIts}
                  selectedOutcomes={selectedOutcomes}
                  addCard={addCard}
                  updateCard={updateCard}
                  refetch={refetch}
                  refetch2={refetch2}
                  refetchGroupsWithCards={refetchGroupsWithCards}
                  source="includes"
                  enableDrag={true}
                />
              </CardWrapper>
            ))
          ) : (
            <Subtitle2 color={colors.greyDark}>No outcomes to include</Subtitle2>
          ))} />

          <StyledComponent>
            <BodyNormal>Don't Include</BodyNormal>
          </StyledComponent>

          {/* drag and drop receiver for included outcomes */}
          <TargetBox source="includes" content={(excludedOutcomes.length > 0? (
            excludedOutcomes.map((postIts, index) => (
              <CardWrapper key={index}>
                {/* <BodyNormal color={colors.purple} style={{ opacity: 0 }}>
                  {index}
                </BodyNormal> */}
                <DraggableOutcomes
                  removeCard={removeCard}
                  addCard={addCard}
                  setIsLoadingOutcomes={setIsLoadingOutcomes}
                  selectedOutcomes={selectedOutcomes}
                  data={postIts}
                  updateCard={updateCard}
                  refetch={refetch}
                  refetch2={refetch2}
                  refetchGroupsWithCards={refetchGroupsWithCards}
                  source="excludes"
                  enableDrag={true}
                />
              </CardWrapper>
            ))
          ) : (
            <Subtitle2 color={colors.greyDark}>No outcomes left out</Subtitle2>
          ))}>
          
          
          </TargetBox>
          <OutcomesDragLayer data={cards} />
        </ContentWrapper>
        </>
        
      </>
      ) : 
      <>
        <div style={{ height: '100%'}}>
          <StyledPostItsEmpty
            title="Cannot add outcomes yet"
          >
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Work can only begin when a Lead has created target patient population
            </BodySmall>
          </StyledPostItsEmpty>
        </div>
      </>
      }
    </Wrapper>,
    document.body
  );
};