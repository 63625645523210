import ErrorPage from 'components/ErrorPage';
import { ModalConfirmDelete } from 'components/ModalConfirmDelete';
import { Heading3, Radio, BodySmall } from 'components/shared';
import FormYearInput from 'components/shared/FormYearInput';
import { currencies } from 'constants/index';
import { colors } from 'constants/colors';
import {
  DrugsDocument,
  useDrugQuery,
  useStrategyDeleteMutation,
  useStrategyQuery,
  useStrategyUpdateMutation,
} from 'data/graphql/generated';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import StrategyCreationPage, {
  ModalStrategy,
} from '../../containers/StrategyCreationPage';

const StrategyPeriodPage = styled.div`
  @media ${device.desktopMin} {
    ${ModalStrategy} {
      height: 470px;
    }
  }
`;

const TimeLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 343px;
  margin-top: 30px;
`;

const StratCurrency = styled.div`
  margin-top: 30px;
`;

const StratCurrencies = styled.div`
  @media (min-width: 555px) {
    display: flex;
    flex-direction: row;
  }
`;

const CurrencyOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 35px;
  cursor: pointer;
  margin: 0px 0px 15px 0px;
  @media (min-width: 555px) {
    margin: 0px 35px 0px 0px;
  }
`;

const StrategyPeriodCreate = () => {
  const [startYear, setStartYear] = React.useState<string>('');
  const [endYear, setEndYear] = React.useState<string>('');
  const [currency, setCurrency] = React.useState<string>('');
  const history = useHistory();
  const [strategyUpdate] = useStrategyUpdateMutation();
  const { drugId, strategyId } = useParams<{
    drugId: string;
    strategyId: string;
  }>();
  const [strategyDelete] = useStrategyDeleteMutation();
  const [
    discardStrategyModal,
    setDiscardStrategyModal,
  ] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nativeEvent = e.nativeEvent as InputEvent;
    const char = nativeEvent.data;
    // only numbers allowed, no spaces, no periods
    if (isNaN(Number(char)) || char === ' ' || char === '.') return;

    if (e.target.name === 'startYear') {
      setStartYear(e.target.value);
    } else {
      setEndYear(e.target.value);
    }
  };

  const openDiscardStrategyModal = () => setDiscardStrategyModal(true);

  async function handleSave() {
    try {
      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: +startYear,
            endYear: +endYear,
            currency,
          },
        },
        refetchQueries: [{ query: DrugsDocument }],
      });

      history.push('/');
    } catch (err) {
      console.error(err);
    }
  }

  const { data: strategyData, error: strategyError } = useStrategyQuery({
    variables: {
      id: Number(strategyId),
    },
  });

  const { data: drugData, loading, error: drugError } = useDrugQuery({
    variables: { id: Number(drugId) },
  });

  async function discardStrategy() {
    try {
      await strategyDelete({
        variables: { id: +strategyId },
        refetchQueries: [{ query: DrugsDocument }],
      });

      console.info(`Strategy with id ${strategyId} successfully deleted`);
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  }

  async function saveAndContinue() {
    try {
      await strategyUpdate({
        variables: {
          id: +strategyId,
          data: {
            startYear: +startYear,
            endYear: +endYear,
            currency,
          },
        },
        refetchQueries: [{ query: DrugsDocument }],
      });

      history.push(`/d/${drugId}/strategy/${strategyId}/create/collaborators`);
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    if (strategyData?.strategy?.isDraft === false) history.push('/');

    const [existingStartYear, existingEndYear] = [
      strategyData?.strategy?.startYear,
      strategyData?.strategy?.endYear,
    ];
    existingStartYear && setStartYear(existingStartYear.toString());
    existingEndYear && setEndYear(existingEndYear.toString());
  }, [strategyData, history]);

  const stepValid =
    endYear >= startYear &&
    [endYear, startYear].every((year) => year.length === 4) &&
    currency !== '';

  if (loading) return <span>Loading...</span>;
  if (strategyError || drugError) return <ErrorPage errorType="generic" />;
  if (!strategyData || !drugData) return <ErrorPage errorType="unpermitted" />;

  return (
    <StrategyPeriodPage>
      <ModalConfirmDelete
        title="Discard strategy?"
        visible={discardStrategyModal}
        text="This strategy and details you’ve provided for it will be lost."
        leftButtonText="Cancel"
        rightButtonText="Discard strategy"
        handleClose={() => setDiscardStrategyModal(false)}
        handleConfirm={discardStrategy}
      />

      <StrategyCreationPage
        title="Timing and Currency"
        brandName={drugData?.drug?.name || ''}
        subtext="Type the years this strategy should start and end and choose a global currency. You can change these later back at the brand page."
        strategyId={Number(strategyId)}
        nextButtonDisabled={!stepValid}
        handleSave={handleSave}
        footerLeftButtonHandler={openDiscardStrategyModal}
        footerNextButtonHandler={saveAndContinue}
      >
        <TimeLine>
          <FormYearInput
            title="Start"
            name="startYear"
            value={startYear}
            onChange={(e) => handleChange(e)}
            className="cypress-strategy-startyear"
          />
          <Heading3>-</Heading3>
          <FormYearInput
            title="End"
            name="endYear"
            value={endYear}
            onChange={(e) => handleChange(e)}
            className="cypress-strategy-endyear"
          />
        </TimeLine>

        <StratCurrency>
          <BodySmall style={{ color: colors.greyDark, marginBottom: 10 }}>
            Global currency
          </BodySmall>

          <StratCurrencies>
            {currencies.map((c) => (
              <CurrencyOption
                className="cypress-strategy-currency"
                onClick={() => setCurrency(c)}
                key={c}
              >
                <Radio checked={c === currency} />
                <BodySmall style={{ marginLeft: 6 }}>{c}</BodySmall>
              </CurrencyOption>
            ))}
          </StratCurrencies>
        </StratCurrency>
      </StrategyCreationPage>
    </StrategyPeriodPage>
  );
};

export default StrategyPeriodCreate;
