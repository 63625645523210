import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, ErrorContainer } from './FormField';
import { BodySmall } from './TextStyles';

interface Props {
  title: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errorMessage?: string;
  className?: string;
}

const Wrapper = styled.div`
  border: 1px solid red;
  height: 65px;
  width: 142px;
  border-radius: 5px;
  border: 1px solid ${colors.greyDark};
  padding: 15px;
  position: relative;
  background: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;

  ${ErrorContainer} {
    top: 70px;
  }
`;

const Input = styled.input`
  border: none;
  width: 100%;
  font-family: ABCFavoritExpanded;
  font-style: normal;
  font-size: 32px;
  line-height: 45px;
  text-align: left;
  color: ${colors.black};

  :focus {
    outline: none;
  }
`;

const Label = styled(BodySmall)`
  background: ${colors.white};
  padding: 0 5px;
  color: ${colors.greyDark};
  position: absolute;
  left: 10px;
  top: -50%;
  transform: translateY(100%);
`;

export const FormYearInput: React.FC<Props> = ({
  onChange,
  value,
  name,
  title,
  disabled,
  errorMessage,
  className,
}) => {
  return (
    <Wrapper>
      <Label>{title}</Label>
      <Input
        autoComplete="off"
        type="text"
        placeholder="YYYY"
        value={value}
        disabled={disabled}
        name={name}
        onChange={onChange}
        className={className}
        maxLength={4}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Wrapper>
  );
};

export default FormYearInput;
