import { PatientsMenu } from 'components/shared';
import { polling, zIndex } from 'constants/index';
import {
  useObservationsQuery,
  ObservationsQueryVariables,
  Stakeholder,
} from 'data/graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { useHistory } from 'react-router-dom';
import { SubMenu } from 'components/shared';

interface Props {
  stakeholder?: Stakeholder;
  offsetTopOverride?: number;
  readonly?: boolean;
  refetchStakeholderDefinitions: ()=> void;
}

const ObservationButtonWrapper = styled.div<{ open: boolean }>`
  z-index: ${zIndex.observationsBtn};

  opacity: ${({ open }) => Number(!open)};
  pointer-events: ${({ open }) => (open ? 'none' : 'all')};
  transition: all 0.1s;
  @media ${device.tabletMax} {
    top: 175px;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

interface Params {
  strategyId: string;
}

export const Patients = ({
  offsetTopOverride,
  readonly,
  refetchStakeholderDefinitions,
}: Props) => {

  const stakeholder = Stakeholder.Patient;
  const [open, setOpen] = useState(false);
  const { strategyId }: Params = useParams();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const observationQuery = query.get('observation');
  const history = useHistory();

  const observationsQueryVars: ObservationsQueryVariables = {
    where: { strategyId: Number(strategyId) },
  };

  const {
    data: Observationsdata,
    loading,
    startPolling,
    stopPolling,
  } = useObservationsQuery({
    variables: {
      ...observationsQueryVars,
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const readonlyData = {
    observations: {
      items: Observationsdata?.observations?.items.filter(
        (data) => data?.stakeholder === stakeholder
      ),
    },
  } as typeof Observationsdata;

  // open observation if set in url (used for notifications)
  useEffect(() => {
    if (!open && observationQuery === 'true') {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observationQuery]);

  useEffect(() => {
    // remove ?observation=true from url
    if (open === false) {
      query.delete('observation');
      history.replace({
        pathname: history.location.pathname,
        search: query.toString(),
      });
    }
  }, [open, history, query]);

  return (
    <div>
      <ObservationButtonWrapper open={false}>
        <SubMenu
            icon="Patient"
            text="Target Population"
            onClick={() => {
              setOpen(true);
            }}
          />
      </ObservationButtonWrapper>
      <PatientsMenu
        refetchStakeholderDefinitions={refetchStakeholderDefinitions}
        readonly={readonly}
        data={readonly ? readonlyData : Observationsdata}
        loading={loading}
        setOpen={setOpen}
        open={open}
        stakeholder={stakeholder || Stakeholder.Patient}
        offsetTopOverride={offsetTopOverride}
      />
    </div>
  );
};
