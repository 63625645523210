import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';

import {
  PostItCard,
  PostItCardCreateMutation,
  PostItCardFragment,
  PostItCardInput,
  PostItCardsDocument,
  PostItCardsQuery,
  PostItCardsQueryVariables,
  PostItCardUpdateMutation,
  Step,
  SubStep,
  usePostItCardCreateMutation,
  usePostItCardDeleteMutation,
  usePostItCardsQuery,
  usePostItCardUpdateMutation,
  Stakeholder,
  PostItCardDeleteMutation,
  PostItCardUpsertedDocument,
  PostItCardDeletedDocument,
} from 'data/graphql/generated';
import { ApolloError, FetchResult } from '@apollo/client';
import client from 'data/apollo';
import {
  apolloCreateHelper,
  apolloUpdateHelper,
} from 'utils/apolloQueryHelpers';

export interface usePostItCardsPayload {
  items: PostItCardFragment[];
  loading: boolean;
  disabled: boolean;
  createCard(
    card: Omit<
      PostItCardInput,
      'step' | 'stakeholder' | 'strategy' | 'drug' | 'substep'
    >
  ): Promise<
    | FetchResult<
        PostItCardCreateMutation,
        Record<string, any>,
        Record<string, any>
      >
    | undefined
  >;
  updateCard(
    card: Omit<PostItCard, 'collaboration'>
  ): Promise<
    | FetchResult<
        PostItCardUpdateMutation,
        Record<string, any>,
        Record<string, any>
      >
    | undefined
  >;
  removeCard(
    cardId: number
  ): Promise<
    | FetchResult<
        PostItCardDeleteMutation,
        Record<string, any>,
        Record<string, any>
      >
    | undefined
  >;
  error: ApolloError | undefined;
}

interface Extras {
  drugId: string | number;
  strategyId: string | number;
  step: Step;
  stakeholder?: Stakeholder;
  substep?: SubStep;
  stakeholderDefinitionId?: string | number;
  competitorId?: string | number;

  competitiveAdvantageRowId?: string | number;
}

export function usePostItCards(
  queryVars: PostItCardsQueryVariables,
  extras: Extras
): usePostItCardsPayload {
  const [disabled, setDisabled] = useState(false);

  const { data, loading, error, subscribeToMore } = usePostItCardsQuery({
    variables: queryVars,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    // NOTE I can't seem to find this in the codegen apollo generated file
    const unsubscribeToUpdates = subscribeToMore({
      document: PostItCardUpsertedDocument,
      variables: queryVars,
      updateQuery: (
        prev,
        {
          subscriptionData,
        }: {
          subscriptionData: { data: { postItCardUpserted: PostItCard } };
        }
      ) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.postItCardUpserted;

        if (!prev?.postItCards?.items) return prev;

        return Object.assign({}, prev, {
          ...prev,
          postItCards: {
            ...prev.postItCards,
            items: uniqBy([newFeedItem, ...prev.postItCards.items], 'id'),
          },
        });
      },
    });

    const unsubscribeToDeletes = subscribeToMore({
      document: PostItCardDeletedDocument,
      variables: queryVars,
      updateQuery: (
        prev,
        {
          subscriptionData,
        }: {
          subscriptionData: { data: { postItCardDeleted: PostItCard } };
        }
      ) => {
        if (!subscriptionData.data) return prev;

        const item = subscriptionData.data.postItCardDeleted;

        if (!prev?.postItCards?.items || !item) return prev;

        return Object.assign({}, prev, {
          ...prev,
          postItCards: {
            ...prev.postItCards,
            items: prev.postItCards.items.filter((p) => p.id !== item.id),
          },
        });
      },
    });

    return () => {
      unsubscribeToUpdates();
      unsubscribeToDeletes();
    };
  }, [subscribeToMore, queryVars]);

  const [cardCreate] = usePostItCardCreateMutation({
    update: (cache, { data }) => {
      const existing = cache.readQuery<
        PostItCardsQuery,
        PostItCardsQueryVariables
      >({ query: PostItCardsDocument, variables: queryVars });

      const newTotal = existing?.postItCards?.total
        ? existing.postItCards.total + 1
        : 1;

      const existingItems = existing?.postItCards?.items || [];

      const newItems = data?.postItCardCreate
        ? [data?.postItCardCreate, ...existingItems]
        : existingItems;

      cache.writeQuery<PostItCardsQuery, PostItCardsQueryVariables>({
        query: PostItCardsDocument,
        variables: queryVars,
        data: {
          postItCards: {
            total: newTotal,
            items: [...newItems],
          },
        },
      });
    },
  });
  const [cardDelete] = usePostItCardDeleteMutation({
    update: (cache, { data }) => {
      const existing = cache.readQuery<
        PostItCardsQuery,
        PostItCardsQueryVariables
      >({ query: PostItCardsDocument, variables: queryVars });

      const newTotal = existing?.postItCards?.total
        ? existing.postItCards.total - 1
        : 0;
      const newItems =
        existing?.postItCards?.items.filter(
          (x) => x.id !== data?.postItCardDelete?.id
        ) || [];

      const cachedItem = existing?.postItCards?.items.find(
        (x) => x.id === data?.postItCardDelete?.id
      );

      cache.evict({
        id: `${data?.postItCardDelete?.__typename}:${data?.postItCardDelete?.id}`,
      });
      cache.evict({ id: `Collaboration:${cachedItem?.collaboration?.id}` });

      cache.writeQuery<PostItCardsQuery, PostItCardsQueryVariables>({
        query: PostItCardsDocument,
        data: {
          postItCards: {
            total: newTotal,
            items: [...newItems],
          },
        },
      });
    },
  });
  const [cardUpdate] = usePostItCardUpdateMutation();

  async function createCard(
    card: Omit<PostItCardInput, 'step' | 'stakeholder' | 'strategy' | 'drug' | 'substep'> & {
      step?: string;
      stakeholder?: string;
      strategy?: string;
      drug?: string;
      substep?: string;
    }
  ) {
    setDisabled(true);

    const data = {
      ...card,
      step: card?.step ?? extras.step,
      stakeholder: card?.stakeholder ?? extras.stakeholder,
      strategy: Number(extras.strategyId),
      drug: Number(extras.drugId),
      substep: extras.substep,
      stakeholderDefinition: card?.stakeholderDefinition ? Number(card?.stakeholderDefinition) : !!extras.stakeholderDefinitionId
        ? Number(extras.stakeholderDefinitionId)
        : undefined,
      competitor: card?.competitor ? Number(card?.competitor) : !!extras.competitorId
        ? Number(extras.competitorId)
        : undefined,
      competitiveAdvantageRow: !!extras.competitiveAdvantageRowId
        ? Number(extras.competitiveAdvantageRowId)
        : undefined,
    } as PostItCardInput;

    const createdCard = await cardCreate({
      variables: {
        data,
      },
      update: apolloCreateHelper({
        responseField: 'postItCardCreate',
        query: PostItCardsDocument,
        queryName: 'postItCards',
        queryVars,
      }),
    });
    setDisabled(false);

    return createdCard;
  }

  async function updateCard(card: PostItCard) {
    const updatedCard = {
      title: card.title,
      typing: card.typing,
      include: card.include,
      typingUserId: card.typingUserId,
      pos: card.pos,
      postItGroup: card.postItGroupId,
      image: card?.image,
    };

    const returnedCard = await cardUpdate({
      variables: {
        id: Number(card.id),
        data: updatedCard,
      },

      optimisticResponse: {
        __typename: 'Mutation',
        postItCardUpdate: {
          ...card,
          ...updatedCard,
          __typename: 'PostItCard',
        },
      },

      update: apolloUpdateHelper({
        responseField: 'postItCardUpdate',
        query: PostItCardsDocument,
        queryName: 'postItCards',
        queryVars,
      }),
    });
    return returnedCard;
  }

  async function removeCard(cardId: number) {
    if (!cardId) return;

    // Get card from proxy
    const data: PostItCardsQuery | null = client.readQuery({
      query: PostItCardsDocument,
      variables: queryVars,
    });

    const card = data?.postItCards?.items.find((card) => card?.id === cardId);

    if (!card) return;

    return await cardDelete({
      variables: {
        id: Number(cardId),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        postItCardDelete: {
          __typename: 'PostItCard',
          ...card,
        },
      },
    });
  }

  return {
    items: data?.postItCards?.items || [],
    loading,
    disabled,
    createCard,
    updateCard,
    removeCard,
    error,
  };
}
