import { ModalForm } from 'components/ModalForm';
import React from 'react';
import styled from 'styled-components';
import { ButtonPill } from 'components/shared';
import { TooltipWrapper } from 'components/shared/ButtonPill';

const Wrapper = styled.div`
  ${TooltipWrapper} {
    width: 180px;
    margin: auto;
  }
`;

interface Props {
  visible: boolean;
  setTreatmentInUseModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TreatmentInUseModal: React.FC<Props> = ({
  visible,
  setTreatmentInUseModal,
}) => {
  return (
    <Wrapper>
      <ModalForm
        heading="Cannot remove treatment in use"
        message="You can only deselect a competitor when it has no notes against it. Check it has no notes in all outcome groups for this stakeholder definition and try again."
        visible={visible}
        handleClose={() => setTreatmentInUseModal(false)}
      >
        <ButtonPill text="Okay" onClick={() => setTreatmentInUseModal(false)} />
      </ModalForm>
    </Wrapper>
  );
};
