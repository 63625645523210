import React, { SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import GradientModal from 'components/shared/GradientModal';
import { Drug, useUserInviteResendMutation, useDrugQuery } from 'data/graphql/generated';
import {
  BodySmall,
  ButtonPill,
  ButtonRound,
  Heading3,
  Icon,
  Subtitle1,
} from 'components/shared';
import { device, size } from 'utils/breakpoints';
import { colors } from 'constants/index';
import {
  AddEditLead,
  RemoveLead,
  CreateRenameBrandModalShape,
} from 'containers/AdminCompany';
import { NameAndStatus } from 'components/shared/NameAndStatus';
import EmailAndInviteButton from 'components/shared/EmailAndInviteButton';

interface Props {
  brand: Drug;
  dropdownVisible: boolean;
  createRenameBrandModal: CreateRenameBrandModalShape;
  setCreateRenameBrandModal: React.Dispatch<
    SetStateAction<CreateRenameBrandModalShape>
  >;
  brandLead: AddEditLead;
  setLead: React.Dispatch<SetStateAction<AddEditLead>>;
  setShowProduct: React.Dispatch<SetStateAction<boolean>>;
  setShowUser: React.Dispatch<SetStateAction<boolean>>;
  setRemoveLeadModal: React.Dispatch<SetStateAction<RemoveLead>>;
}

const Wrapper = styled(GradientModal)`
  border: none;
  border-radius: 5px;
  max-width: 928px;
  margin: 0 auto 30px auto;
  z-index: 1;
  overflow: visible;

  > .wrapperTop {
    border-radius: 5px 5px 0 0;
  }

  @media ${device.tabletMin} {
    border-radius: 5px;
    border: 1px solid rgba(20, 20, 39, 0.1);
  }
`;

const ContentWrapper = styled.div`
  padding: 15px;
  position: relative;

  @media (min-width: ${size.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const EditIcon = styled(Icon)`
  background-color: ${colors.cream};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-top: 5px;

  svg {
    fill: ${colors.black};
  }
`;

const NameAndEditIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${device.tabletMin} {
    justify-content: flex-start;
  }

  @media ${device.desktopMin} {
    margin-right: 15px;
  }
`;

const DrugName = styled(Heading3)`
  line-height: 45px;
  background-image: ${colors.coloredGradient};
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  // max-width: calc(100% - 45px);
  width: fit-content;
  overflow-wrap: break-word;
  hyphens: auto;
  margin-right: 15px;
`;

const Leads = styled.div`
  min-height: 105px;
  width: 100%;
  background: ${colors.white};
  border: 1px solid ${colors.black30};
  border-radius: 5px;
  padding: 15px 15px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${size.tablet}) {
    width: 441px;
  }
`;

const LeadsTitle = styled(Subtitle1)`
  color: ${colors.greyDark};
`;

const TitleAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Lead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.greyLight};
  padding: 15px 0;

  :last-child {
    border-bottom: none;
  }
`;

const LeadDetails = styled.div``;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const NoLeads = styled(BodySmall)`
  color: ${colors.greyDark};
  margin: 15px 0;
`;

const MoreMenuContainer = styled.div`
  position: relative;
`;
const ProductName = styled.div`
color: var(--Grey---Dark, #696975);
/* Subtitle 1 */
font-size: 22px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  width: 140px;
  top: 35px;
  left: 0;
  background: white;
  border: 1px solid #e8e8e9;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  border-radius: 5px;
  z-index: 2;
`;

const DropdownItem = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: ${colors.greyLight};
  }

  svg {
    margin-right: 10px;
  }

  &.delete {
    color: ${colors.red};
  }
`;

const ModalBrand: React.FC<Props> = ({
  brand,
  dropdownVisible,
  brandLead,
  setLead,
  setShowProduct,
  setShowUser,
  createRenameBrandModal,
  setCreateRenameBrandModal,
  setRemoveLeadModal,
}: Props) => {
  const { name, users, id: drugId, companyId } = brand;

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const [userInviteResend] = useUserInviteResendMutation();
  const {
    data: drugData,
  } = useDrugQuery({
    variables: { id: Number(drugId) },
  });
  
  async function handleResendInvite(userId: number) {
    if (!userId) return alert('No id provided');

    try {
      await userInviteResend({
        variables: {
          data: {
            companyId,
            drugId,
            userId,
          },
        },
      });

      return true;
    } catch (err) {
      console.error(err);
      alert(err);
      return false;
    }
  }

  useEffect(() => {
    if (dropdownVisible) setDropdownVisible(false);
  }, [dropdownVisible]);

  return (
    <Wrapper>
      <ContentWrapper>
        <NameAndEditIcon>
          <div>
          <DrugName>{name}</DrugName>
          {drugData?.drug?.ProductOnDrugs?.map((prod) => 
          <ProductName>{prod.Product?.productName}</ProductName>)}
          </div>

          <div style={{ position: 'relative' }}>
            <EditIcon
              name="More"
              size={20}
              color={colors.black}
              onClick={toggleDropdown}
            />
            {isDropdownVisible && (
              <DropdownMenu>
                <DropdownItem
                   onClick={() => {
                    setLead({ ...brandLead, drugId, modalType: 'ADD' })
                    setShowProduct(true)
                    setDropdownVisible(false)
                  }}
                >
                  <Icon name="Strategy" color={colors.black} size={30} />
                  Product
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setCreateRenameBrandModal({
                      ...createRenameBrandModal,
                      companyId,
                      modalType: 'RENAME',
                      name,
                      drugId,
                    })
                    setDropdownVisible(false)
                  }
                  }
                >
                  <Icon name="Pencil" color={colors.black} size={30} />
                  Rename
                </DropdownItem>
                <DropdownItem className="delete">
                  <Icon name="Trash"  color={colors.red} size={30} />
                  Delete
                </DropdownItem>
              </DropdownMenu>
            )}
          </div>
          

          {/* <TitleAndButton>
            <LeadsTitle>Leads</LeadsTitle>
            <ButtonPill
              className={'Cypress-add-lead-button'}
              text="Add lead"
              onClick={() =>
                setLead({ ...brandLead, drugId, modalType: 'ADD' })
              }
              level="primary-solid"
            />
          </TitleAndButton> */}

        </NameAndEditIcon>

        <Leads>
          <TitleAndButton>
            <LeadsTitle>Leads</LeadsTitle>
            <ButtonPill
              className={'Cypress-add-lead-button'}
              text="Add lead"
              onClick={() => {
                setLead({ ...brandLead, drugId, modalType: 'ADD' })
                setShowUser(prev => !prev)
              }}
              level="primary-solid"
            />
          </TitleAndButton>

          <List>
            {users?.length ? (
              users.map((lead) => {
                if (!lead) return <NoLeads />;
                const { name, email, verifiedEmail, id: userId } = lead;

                return (
                  <Lead key={userId} className="lead-item">
                    <LeadDetails>
                      <NameAndStatus
                        name={name}
                        verifiedEmail={verifiedEmail}
                        displayStatus={true}
                      />
                      <EmailAndInviteButton
                        verifiedEmail={verifiedEmail}
                        email={email}
                        displayStatus={true}
                        resendInvite={() => handleResendInvite(userId)}
                      />
                    </LeadDetails>

                    <MoreMenuContainer>
                      <ButtonRound
                        iconName="Trash"
                        level="secondary"
                        size="small"
                        tooltip=""
                        onClick={() => {
                          setRemoveLeadModal({ userId, name, drugId });
                        }}
                        className="cypress-lead-delete-btn"
                      />
                    </MoreMenuContainer>
                  </Lead>
                );
              })
            ) : (
              <NoLeads>No leads. Name the brand, then add a lead.</NoLeads>
            )}
          </List>
        </Leads>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ModalBrand;