import { Button } from 'components/Button';
import { Navbar } from 'components/Navbar';
import { BodyNormal, ButtonPill, Heading3, Subtitle1 } from 'components/shared';
import { colors } from 'constants/colors';
import useDesktop from 'hooks/useDesktop';
import useMobile from 'hooks/useMobile';
import useTablet from 'hooks/useTablet';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
import { AddCollaboratorButton } from 'components/CreateStrategy/StrategyCollaboratorsCreate';
interface Props {
  brandName: string;
  title: string;
  subtext: string;
  children?: React.ReactNode;
  strategyId: number;
  nextButtonDisabled: boolean;
  handleSave: () => void;
  footerLeftButtonHandler: () => void;
  footerNextButtonHandler: () => void | Promise<any>;
  addCollaboratorButtonHandler?: () => void;
}

export const PageWrapper = styled.div`
  padding-top: 50px;
  height: 100vh;
  width: 100vw;
  position: fixed;

  @media ${device.desktopMin} {
    padding: 50px 138px;
    overflow: scroll;
  }
`;

export const ModalStrategy = styled.div`
  height: 100%;
  padding: 30px 15px 101px;
  background: ${colors.white};
  position: relative;
  overflow: scroll;

  @media ${device.desktopMin} {
    max-width: 1164px;
    margin: 30px auto;
    height: fit-content;
    padding: 30px 30px 101px 30px;
    border-radius: 5px;
    overflow: hidden;
  }
`;

const Heading = styled(Subtitle1)`
  color: ${colors.greyDark};
  margin-bottom: 15px;
`;

const StepTitle = styled(Heading3)`
  line-height: 45px;
`;

const Description = styled(BodyNormal)`
  max-width: 693px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: ${colors.white};

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${colors.greyLight};

  @media ${device.desktopMin} {
    padding: 30px;
    border: none;
  }
`;

const FooterButton = styled(Button)`
  padding: 0;
  cursor: pointer;

  span {
    color: ${colors.black};
  }
`;

const SaveButton = styled(FooterButton)`
  padding: 0 15px 0 0;
`;

const SaveAndNext = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
`;

const NextButton = styled(ButtonPill)`
  width: 145px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  @media ${device.tabletMin} {
    margin-bottom: 10px;
  }
`;

const StrategyCreationPage: React.FC<Props> = ({
  children,
  title,
  subtext,
  brandName,
  strategyId,
  nextButtonDisabled,
  handleSave,
  footerLeftButtonHandler,
  footerNextButtonHandler,
  addCollaboratorButtonHandler,
}) => {
  const [loading, setLoading] = useState(false);

  const isMobile = useMobile();
  const history = useHistory();
  const isDesktop = useDesktop();
  const isTablet = useTablet();
  const isPeriodPage = history.location.pathname.includes('period');
  const isDescriptionsPage = history.location.pathname.includes('descriptions');
  const isCollaboratorsPage = history.location.pathname.includes(
    'collaborators'
  );

  const footerLeftButtonText = isPeriodPage
    ? isMobile
      ? 'Discard'
      : 'Discard strategy'
    : 'Back';

  const footerNextButtonText = isDescriptionsPage ? 'Publish strategy' : 'Next';

  return (
    <PageWrapper>
      <Navbar
        disableSecondary
        exclude={['drugLink', 'filesLink', 'strategyLink']}
      />

      <ModalStrategy>
        <Heading>Create a new strategy for {brandName}</Heading>
        <TitleContainer>
          <StepTitle>{title}</StepTitle>
          {isTablet && isCollaboratorsPage && (
            <AddCollaboratorButton
              onClick={() => addCollaboratorButtonHandler?.()}
            />
          )}
        </TitleContainer>
        <TitleContainer>
          <Description>{subtext}</Description>
          {isDesktop && isCollaboratorsPage && (
            <AddCollaboratorButton
              onClick={() => addCollaboratorButtonHandler?.()}
            />
          )}
        </TitleContainer>
        {children}
        {isDesktop && (
          <Footer>
            <FooterButton
              text={footerLeftButtonText}
              onClick={footerLeftButtonHandler}
            />
            <SaveAndNext>
              <SaveButton text="Save and exit" onClick={handleSave} />
              <NextButton
                loading={loading}
                text={footerNextButtonText}
                disabled={nextButtonDisabled}
                onClick={async () => {
                  setLoading(true);
                  await footerNextButtonHandler();
                  setLoading(false);
                }}
                className="cypress-strategy-next"
              />
            </SaveAndNext>
          </Footer>
        )}
      </ModalStrategy>

      {!isDesktop && (
        <Footer>
          <FooterButton
            text={footerLeftButtonText}
            onClick={footerLeftButtonHandler}
          />
          <SaveAndNext>
            <SaveButton text="Save and exit" onClick={handleSave} />
            <NextButton
              text={footerNextButtonText}
              disabled={nextButtonDisabled}
              onClick={footerNextButtonHandler}
            />
          </SaveAndNext>
        </Footer>
      )}
    </PageWrapper>
  );
};

export default StrategyCreationPage;
