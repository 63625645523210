import React, { useEffect, useRef, useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import styled, { css } from 'styled-components/macro';
import { colors } from 'constants/colors';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Icon, IconContainer } from '../Icon';
import { DeleteConfirmation, DeleteConfirmationFooter, StyledDeleteButton } from 'components/Insight';
import { TextareaWrapper } from 'components/4-2-medical-strategy/shared/feature-key-events-sidebar/src/lib/ui/KeyEvent.styles';
import { BodyNormal, ButtonLabel } from '../TextStyles';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import { usePostItCards, usePostItCardsPayload } from 'hooks/usePostItCards';
import { Step } from 'data/graphql/generated';
import { usePostItGroups } from 'hooks/usePostItGroups';

const Wrapper = styled.div<{ isDragging: boolean; enableDrag: boolean; showDeleteButton: boolean; confirmDelete: boolean }>`
  width: 100%;
  display: flex;
  position: relative;

  ${IconContainer} {
    cursor: ${({ enableDrag, showDeleteButton }) => (enableDrag && !showDeleteButton ? 'grab' : 'default')};
    background: transparent;
    
  }

  ${BodyNormal} {
    display: flex;
    align-items: center;  
  }

  ${StyledDeleteButton} {
  opacity: ${({ showDeleteButton, confirmDelete }) => (showDeleteButton && !confirmDelete ? 1 : 0)};
  cursor: ${({ showDeleteButton }) => (showDeleteButton ? 'pointer' : 'default')};
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    background: ${colors.white50};
    border-radius: 5px;
    display: ${({ isDragging }) => (isDragging ? 'block' : 'none')};
  }

  ${({ isDragging }) => {
    if (isDragging) {
      return css`
        > * {
          opacity: 0;
          transition: opacity 0s;
        }
      `;
    }
  }}
`;
interface Props {
  data: any;
  onEnd?: (dropResult: unknown, monitor: DragSourceMonitor) => void;
  source: string;
  enableDrag?: boolean;
  index?: number;
  removeCard: usePostItCardsPayload['removeCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  refetchGroupsWithCards: () => void;
  refetch: () => void;
}

export const DraggableCard: React.FC<Props> = ({
  data,
  onEnd,
  removeCard,
  updateCard,
  source,
  refetch,
  refetchGroupsWithCards,
  enableDrag,
  index,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [textValue, setTextValue] = useState<string>(data?.title || '');
  const [id, setId] = useState<string>(data?.id || '');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    items: groupItems,
    updateGroup,
  } = usePostItGroups(
    {
      where: {
        strategyId:  Number(data?.strategyId),
        step: Step.Competitivelandscape,

      },
    },
    {
      strategyId: Number(data?.strategyId),
      drugId: data?.drugId,
      step: Step.Competitivelandscape,
    }
  );

  const {
    items: cardItems,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(data?.strategyId),
        step: Step.Keystakeholders,
      },
    },
    {
      strategyId: data?.strategyId,
      drugId: data?.drugId,
      step: Step.Keystakeholders,
      // stakeholder,
      // stakeholderDefinitionId: Number(stakeholderDefinitionId),
      // competitorId: Number(competitorId),
    }
  );


  const [{ isDragging }, drag, preview] = useDrag(() => ({
    item: { type: source, id: data?.id, source, data },  // Ensure you're using the correct data.id here
    collect: (monitor) => ({
      canDrag: monitor.canDrag(),
      isDragging: !!monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      console.log(monitor, dropResult, data?.id, id, 'monitor at drag end');
      onEnd?.(dropResult, monitor);
    },
  }), [data?.id, source]); 

  useEffect(() => {
    setId(data?.id)
    setTextValue(data?.title)
  },[data?.id, data?.title]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useClickOutsideComponent(
    containerRef,
    () => {
      if (!!textValue) {
        setShowDeleteButton(false);
        setTextValue('');
        setIsUpdating(false)
        confirmDelete && setConfirmDelete(false);
      }
    }
  );

  useClickOutsideComponent(textAreaRef, () => {
    setShowDeleteButton(false);
    setIsUpdating(false)
  });

  const text = data?.title || '';

  const checkExists = async(text: string ) => {
    const exists = cardItems.some((item: any) => item.title === text);
  
    return exists;
  }
  
  return (
    <>
      <Wrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        confirmDelete={confirmDelete}
        showDeleteButton={showDeleteButton}
        enableDrag={!!enableDrag}
        isDragging={isDragging}
        ref={enableDrag && !showDeleteButton && !confirmDelete ? drag : null}
      >
        <BodyNormal>
          {index}
        </BodyNormal>
        <IconContainer
          size={30}
          color={colors.greyDark}
          fillColor='transparent'
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <Icon name="Grip" size={30} />
        </IconContainer>
        <DeleteConfirmation show={confirmDelete}>
          <div>
            <BodyNormal color={colors.black}>
              Delete this outcome?
            </BodyNormal>
          </div>
          <DeleteConfirmationFooter>
            <ButtonLabel
              onClick={() => {
                setConfirmDelete(false);
              }}
            >
              Cancel
            </ButtonLabel>
            <ButtonLabel
              className="cypress-observation-delete-confirm"
              color={colors.red}
              onClick={async (e) => {
                e.stopPropagation();
                if (Number.isInteger(data?.id) && data?.id) {
                  await removeCard(data?.id);
                  refetch();
                  refetchGroupsWithCards();
                }
                setConfirmDelete(false);
                setShowDeleteButton(true);
              }}
            >
              Delete
            </ButtonLabel>
          </DeleteConfirmationFooter>
        </DeleteConfirmation>
        <TextareaWrapper
          onClick={e => e.stopPropagation()}
          ref={textAreaRef}
          onFocus={() => {
            setShowDeleteButton(true);
            setIsUpdating(true)
          }}
          onBlur={async (e) => {
            const deleteBtn = deleteButtonRef.current;
            const newFocusedElement = e.relatedTarget as Node | null;

            if (newFocusedElement) {
              if (!deleteBtn?.contains(newFocusedElement)) {
                setShowDeleteButton(false);
              }
            }

            // update outcome card
            if (!!textValue && textValue !== text && data && isUpdating) {
              const exists = await checkExists(textValue);
              if (exists) {
                setTextValue(text)
                return;
              }
              await updateCard({
                ...data,
                title: textValue,
              });

              cardItems.map(async (cItem) => {
                if (text !== cItem.title) return;
                await updateCard({
                  ...cItem,
                  title: textValue,
                });
              })

              groupItems.map(async (gItem: any) => {
                if (text !== gItem.title) return;
                await updateGroup({
                  ...gItem,
                  title: textValue,
                });
              })
             
              refetch();
              refetchGroupsWithCards()
            }
          }}
          onChange={(e) => {
            if (isUpdating) {
              const target = e.target as HTMLTextAreaElement;
              setTextValue(target.value);
            }
          }}
          $text={!!text}
          $textValue={!!textValue}
          rows={1}
          value={textValue}
        />
        <StyledDeleteButton
          className="cypress-observation-delete"
          innerRef={deleteButtonRef}
          iconName="Trash"
          level="secondary"
          size="small"
          tooltip=""
          onClick={() => {
            setConfirmDelete(true);
            setShowDeleteButton(false);
          }}
          show={showDeleteButton}
        />
      </Wrapper>
    </>
  );
};
