import React, { useEffect, useState, useRef, useCallback, Dispatch, SetStateAction } from 'react';
import { DragSourceMonitor } from 'react-dnd';
import styled from 'styled-components/macro';
import { colors } from 'constants/index';
import { DraggableCard } from './DraggableCard';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import { useParams } from 'react-router-dom';
import { Alignment, PostItCardType, Stakeholder, Step, useAlignmentCreateMutation } from 'data/graphql/generated';
import { PostItGroupAndCards } from 'types';

const OutcomesWrapper = styled.div<{ canDrop: boolean }>`
  border-radius: 5px;
  border: 0.5px solid var(--black-30, rgba(20, 20, 39, 0.30));
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: left;
  position: relative;
  width: 100%;
`;

const Wrapper = styled.div<{ isDragging: boolean }>`
  width: 100%;
  background: ${({ isDragging }) => (isDragging ? `linear-gradient(
      0deg,
      rgba(20, 20, 39, 0.05),
      rgba(20, 20, 39, 0.05)
    ),
    #ffffff` : colors.white)};
  padding: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

interface Props {
  data: any;
  onEnd?: (dropResult: unknown, monitor: DragSourceMonitor) => void;
  removeCard: usePostItCardsPayload['removeCard'];
  addCard: usePostItCardsPayload['createCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  selectedOutcomes: PostItGroupAndCards | undefined;
  source: string;
  enableDrag?: boolean;
  refetch: () => void;
  refetch2: () => void;
  
  setIsLoadingOutcomes: Dispatch<SetStateAction<boolean>>;

  refetchGroupsWithCards: () => void;
  index?: number;
}

interface URLParams {
  stakeholder: Stakeholder
  strategyId: string
  stakeholderDefinitionId: string;
  competitorId: string;
}

export const DraggableOutcomes: React.FC<Props> = ({
  data,
  index,
  onEnd,
  refetch,
  refetch2,
  refetchGroupsWithCards,
  updateCard,
  addCard,
  setIsLoadingOutcomes,
  removeCard,
  source,
  selectedOutcomes,
  enableDrag = true,
}) => {
    const {
      stakeholder,
      strategyId,
      competitorId,
      stakeholderDefinitionId

    }: URLParams = useParams();
  const [loadingOutcomes, setIsLoading] = useState(false);

    const stakeholderDefinitionIdRef = useRef(stakeholderDefinitionId);
    const competitorIdRef = useRef(competitorId);
    const stakeholderRef = useRef(stakeholder);
    const [, setCard] = useState<any>(null);
    const [createAlignment] = useAlignmentCreateMutation();

    useEffect(() => {
      stakeholderDefinitionIdRef.current = stakeholderDefinitionId;
      competitorIdRef.current = competitorId;
      stakeholderRef.current = stakeholder;
    }, [stakeholderDefinitionId, competitorId, stakeholder]);

  // Update the callback to use the latest values
  const handleEndDrag = useCallback(async (dropResult, monitor) => {
    setIsLoading(true)
    setIsLoadingOutcomes(true)
    if (monitor.didDrop()) {

      if (monitor.getItem().source === 'includes' && monitor.getItem()?.id) {
        // TODO: modify this to update dragging functionality
        // triggers when being dragged into DONT INCLUDE LIST
        // await updateCard({ ...monitor.getItem()?.data, include: false });
        await removeCard(monitor.getItem()?.id)
        console.log('LOG include: ', monitor.getItem()?.data, monitor.getItem()?.data?.stakeholder, stakeholder);

        refetch();
        refetch2();
        refetchGroupsWithCards();
      } else if (monitor.getItem().source === 'excludes' && monitor.getItem()?.id) {
        // TODO: modify this to update dragging functionality
        // triggers when being dragged into INCLUDES LIST
        console.log('LOG excludes: ', monitor.getItem()?.data, monitor.getItem()?.data?.stakeholder, stakeholder);

        const card = await addCard({
          ...(stakeholderRef.current ? {stakeholder: stakeholderRef.current} : undefined),
          competitor: parseInt(competitorIdRef.current),
          ...(stakeholderDefinitionId ? {stakeholderDefinition: Number(stakeholderDefinitionIdRef.current)} : undefined),
          postItGroup: Number(selectedOutcomes?.id),
          pos: Number(monitor.getItem()?.data?.pos) + 1000,
          title: monitor.getItem()?.data?.title,
          include: true,
          ...(Step.Keystakeholders ? { step: Step.Keystakeholders } : undefined),
          type: PostItCardType.Plain,
        });

        refetch();
        refetch2();
        refetchGroupsWithCards();
        
        setCard(card);
        const collaborationId = card?.data?.postItCardCreate?.collaboration?.id ?? null;

        // create alignment for created outcome
        if (collaborationId && strategyId) {
          await createAlignment({
            variables: {
              data: { collaborationId, strategyId: Number(strategyId) },
            },
            update: (cache, { data }) => {
              cache.modify({
                id: cache.identify({
                  __ref: `Collaboration:${data?.alignmentCreate.collaborationId}`,
                }),
                fields: {
                  alignmentCount(alignment) {
                    return alignment + 1;
                  },
                  alignments(
                    alignments: Pick<
                      Alignment,
                      'id' | 'createdAt' | 'collaborationId'
                    >[]
                  ) {
                    return [...alignments, data?.alignmentCreate];
                  },
                },
              });
            },
          });
          refetch()
          refetch2();
          refetchGroupsWithCards();
        }
      }
    }      
    setIsLoading(false)
    setIsLoadingOutcomes(false)
  }, [stakeholder, stakeholderDefinitionId, addCard, createAlignment, refetch,
    refetchGroupsWithCards, removeCard, selectedOutcomes, strategyId, refetch2, setIsLoadingOutcomes
  ]); // Ensure the latest values are used in the callback

  if (!stakeholder || !stakeholderDefinitionId) {
    return <div>Loading...</div>;  // or some loading indicator
  }

  return (
    <OutcomesWrapper canDrop={false}>
      <Wrapper isDragging={false}>
        <DraggableCard
          onEnd={handleEndDrag}
          refetch={refetch}
          refetchGroupsWithCards={refetchGroupsWithCards}
          enableDrag={enableDrag && !loadingOutcomes}
          removeCard={removeCard}
          updateCard={updateCard}
          data={data}
          index={index}
          source={source}
        />
      </Wrapper>
    </OutcomesWrapper>
  );
};